import useSWR from "swr";
import { ApiRoutes } from "../../../api/ApiRoutes";
import { ITeam } from "../../../types/ApiTypes";
import { ICollection } from "../../../types/collection.types";

export const useTeams = () => {
    const { data, isLoading, mutate } = useSWR<ICollection<ITeam>>(ApiRoutes.Teams.All);

    return {
        teams: data?.items ?? [],
        teamsById: data?.byId ?? {},
        loadingTeams: isLoading,
        reloadTeams: mutate
    }
} 