import { Message } from "@microsoft/microsoft-graph-types";
import React from "react";
import Button from "../../components/buttons/Button";
import SearchableComboBox from "../../components/comboBox/SearchableComboBox";
import Flex from "../../components/container/Flex";
import OfficeInbox, { IOfficeInboxProps } from "../../components/inbox/OfficeInbox";
import OfficeInboxMessageView from "../../components/inbox/OfficeInboxMessageView";
import OfficeMailForm from "../../components/inbox/OfficeMailForm";
import PageHeader from "../../components/label/PageHeader";
import LoadingSpinner from "../../components/loader/LoadingSpinner";
import PageLoader from "../../components/loader/PageLoader";
import SearchBox from "../../components/search/SearchBox";
import TabSwitcher from "../../components/tabswitcher/TabSwitcher";
import { useAppDispatch } from "../../hooks/reduxHooks";
import useFilteredOfficeMails, { OfficeMailboxTab } from "../../hooks/useFilteredOfficeMails";
import useRoleUtil from "../../hooks/useRoleUtil";
import Page from "../../layout/Page";
import { setRightSidebarExpanded } from "../../state/slices/sidebarSlice";
import { useCurrentUserOfficeAccount } from "../../state/swr/office/useCurrentUserOfficeAccount";
import { useMainTenantOfficeAccount } from "../../state/swr/office/useMainTenantOfficeAccount";
import { useOfficeAccounts } from "../../state/swr/office/useOfficeAccounts";
import { useTeams } from "../../state/swr/teams/useTeams";
import { useCurrentTenantAuthority } from "../../state/swr/user/useCurrentTenantAuthority";
import { useCurrentTenantRole } from "../../state/swr/user/useCurrentTenantRole";
import { useUser } from "../../state/swr/user/useUser";
import { IOfficeAccount, UserRole } from "../../types/ApiTypes";
import "./OfficeInboxPage.css";


export default function OfficeInboxPage() {

    const [editorVisible, setEditorVisible] = React.useState<boolean>(false);
    const [isResponding, setIsResponding] = React.useState<boolean>(false);
    const [isRespondingAll, setIsRespondingAll] = React.useState<boolean>(false);
    const [selectedFolder, setSelectedFolder] = React.useState<string>("inbox");
    const [selectedFolderTitle, setSelectedFolderTitle] = React.useState<string>("Posteingang");
    const [selectedMail, setSelectedMail] = React.useState<Message>();
    const [account, setAccount] = React.useState<IOfficeAccount>();
    const [canSeeTeams, setCanSeeTeams] = React.useState<boolean>(false);
    const [canSeeTenant, setCanSeeTenant] = React.useState<boolean>(false);
    const [canSeeAll, setCanSeeAll] = React.useState<boolean>(false);

    const { 
        isRole 
    } = useRoleUtil();
    
    const { view, updateFilter, filterText } = useFilteredOfficeMails();

    const { user } = useUser();
    const { authority } = useCurrentTenantAuthority();
    const { role } = useCurrentTenantRole();

    const { teams, loadingTeams } = useTeams();

    const dispatch = useAppDispatch();

    const { currentUserOfficeAccount, loadingCurrentUserOfficeAccount, reloadCurrentUserOfficeAccount } = useCurrentUserOfficeAccount();
    const { loadingOfficeAccounts, officeAccounts, reloadOfficeAccounts } = useOfficeAccounts();
    const { loadingTenantOfficeAccount, reloadTenantOfficeAccount, tenantOfficeAccount } = useMainTenantOfficeAccount();

    const openMail = (m: Message | undefined) => {
        dispatch(setRightSidebarExpanded(true));
        setSelectedMail(m);
    }
    const respondToMail = (respondAll?: boolean) => {
        setEditorVisible(true);
        setIsResponding(true);
        setIsRespondingAll(!!respondAll);
    }

    const discardMail = () => {
        setEditorVisible(false);
        setIsResponding(false);
    }

    React.useEffect(() => {
        setCanSeeTenant(false);
        setCanSeeTeams(false);
        setCanSeeAll(false);

        if (!user) return;

        if (user.isSuperAdmin || (authority && authority.isTenantAdmin)) {
            setCanSeeTenant(true);
            setCanSeeTeams(true);
            setCanSeeAll(true);
            return;
        }
        
        if (!isRole(UserRole.Secretary) && !isRole(UserRole.Partner)) return;
    
        setCanSeeTenant(true);
        setCanSeeTeams(true);

    }, [user, role, teams, loadingCurrentUserOfficeAccount, loadingTeams, authority]);
    
    React.useEffect(() => {
        setSelectedFolder("inbox");
    }, [account]);

    React.useEffect(() => {
        const realAccount = (
            view === OfficeMailboxTab.Personal
            ? currentUserOfficeAccount
            : (view === OfficeMailboxTab.Tenant ? tenantOfficeAccount : account)
        );
        setAccount(realAccount);
    }, [view, currentUserOfficeAccount, loadingCurrentUserOfficeAccount, tenantOfficeAccount, loadingTenantOfficeAccount]);

    const getContent = () => {
        const props: IOfficeInboxProps = {
            setEditorVisible: setEditorVisible,
            setSelectedFolder: (val?: string) => setSelectedFolder(val || ""),
            setSelectedFolderTitle: (val?: string) => setSelectedFolderTitle(val || ""),
            setSelectedMail: openMail,
            account: account,
            isEditorVisible: editorVisible,
            isResponding: isResponding,
            selectedFolder: selectedFolder,
            selectedFolderTitle: selectedFolderTitle,
            selectedMail: selectedMail,
            showAll: view === OfficeMailboxTab.All,
        }

        return <OfficeInbox {...props} />;
    }

    return (
        <Page
            widerSidebar
            rightSidebar={selectedMail && account && (
                <>
                    {
                        selectedMail && (
                            <OfficeInboxMessageView 
                                item={selectedMail} 
                                respond={respondToMail} 
                                folderId={selectedFolder} 
                                account={account} 
                                closeMessage={() => setSelectedMail(undefined)} 
                            />
                        )
                    }
                   
                </>
            )}
        >
            <Flex className="w-100 h-100" >
                <Flex row gap={5} justify="between" className="w-100">
                    <Flex className="w-100" row gap={3}>
                        <PageHeader text={selectedFolderTitle ? selectedFolderTitle : "Posteingang"} />
                        {
                            account && <Button iconSize={30} onClick={async () => setEditorVisible(true)} icon="plus-circle-fill" variant="icon" />
                        }
                    </Flex>
                    <SearchBox 
                        initialValue={filterText}
                        onChange={val => updateFilter({search: val})}
                        delay={500}
                    />
                </Flex>
                {
                    (canSeeTenant || canSeeTeams) && (
                        <Flex className="w-100" row wrap justify="between">
                            <TabSwitcher
                                tabs={[
                                    {
                                        data: OfficeMailboxTab.Personal,
                                        label: "Ihr Postfach",
                                        icon: "person-fill"
                                    },
                                    {
                                        data: OfficeMailboxTab.Teams,
                                        label: "Teams",
                                        hidden: !canSeeTeams,
                                        icon: "people-fill"
                                    },
                                    {
                                        data: OfficeMailboxTab.Tenant,
                                        label: "Tenant",
                                        hidden: !canSeeTenant,
                                        icon: "building"
                                    },
                                    {
                                        data: OfficeMailboxTab.All,
                                        label: "Alle Postfächer",
                                        hidden: !canSeeAll,
                                        backColor: "#314AF0",
                                        icon: "inboxes-fill"
                                    }
                                ]}
                                tabQueryParamKey="view"
                            />
                            {
                                view === OfficeMailboxTab.All && (
                                    loadingOfficeAccounts 
                                    ? <LoadingSpinner />
                                    : (
                                        officeAccounts && officeAccounts.length 
                                        ? (
                                            <SearchableComboBox
                                                value={account}
                                                values={officeAccounts}
                                                itemToId={item => item._id}
                                                itemToString={item => item.accountMail}
                                                onItemClick={item => setAccount(item)}
                                                placeholder="Bitte Postfach auswählen..."
                                            />
                                        )
                                        : <span>Keine Mailkonten verknüpft</span>
                                    )
                                )
                            }
                        </Flex>
                    )
                } 
                <Flex className="h-100" style={{overflowY: "auto"}}>
                    {
                        (loadingOfficeAccounts || loadingTenantOfficeAccount || loadingCurrentUserOfficeAccount || loadingTeams )
                        ? <LoadingSpinner />
                        : getContent()
                    }
                </Flex>
                {
                    editorVisible && account && (
                        <OfficeMailForm 
                            respondAll={isRespondingAll} 
                            item={isResponding && selectedMail ? selectedMail : undefined} 
                            account={account} 
                            discardMail={discardMail}
                        />
                    )
                }
            </Flex>            
        </Page>
    )
}