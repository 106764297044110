import React from "react";
import { useTenant } from "../state/swr/tenant/useTenant";
import { DocumentClearanceReminderType, ITenant, ITenantSettings, TenantLogoStyle } from "../types/ApiTypes";

export default function useTenantSettings(useCurrent: boolean = true, tenant: ITenant | null = null, applyGlobally: boolean = true): ITenantSettings {
    const { tenant: currentTenant } = useTenant();

    const getSettings = (): ITenantSettings => {
        const settings: ITenantSettings = {
            options: {
                usersReceiveUnsafeAttachmentsPerMail: true,
                dontSendTicketErrorMails: true,
                dontSendTicketErrorMailsWithPersonalAccount: true,
                sendMailsPreferrablyWithPersonalAccounts: true,
                sendTicketMailsToClients: true,
                documentClearance: {
                    reminderType: DocumentClearanceReminderType.LinkAndText,
                    defaultReminderCount: 3,
                    defaultReminderDayOfMonth: 25,
                    defaultReminderInterval: 1
                },
                interfaces: {
                    datev: {
                        canCreateClientContactsManually: false,
                        overwriteUserDataOnImport: true,
                        defaultResponsibilityForClientManager: ""
                    }
                }
            },
            mails: {
                sendTicketOpenedMail: false,
            },
            tickets: {
                sendMessageHistoryWithEveryTicketMail: false,
                maxMinutesToFirstResponse: 0,
                escalationSupervisor: ""
            },
            ui: {
                sidebar: {
                    logoStyle: TenantLogoStyle.LogoAndText
                }
            },
            theme: {
                icon: "",
                logo: "",
                primaryColor: ""
            },
            titles: {
                siteHeader: "Ticketsystem",
                tenantWord: "Tenant",
                documentClearanceWord: "Belegabgabe"
            },
            links: {
                imprint: "",
                privacyPolicy: ""
            }
        };
        
        const usedTenant = useCurrent ? currentTenant : tenant;
    
        if (!usedTenant || !usedTenant.settings) return settings;

        const result = {...settings, ...(usedTenant.settings ?? {})};
        result.options = {...settings.options, ...(usedTenant.settings.options ?? {})}; 
        result.ui = {...settings.ui, ...(usedTenant.settings.ui ?? {})};
        result.tickets = { ...settings.tickets, ...(usedTenant.settings.tickets ?? {})};  
        result.theme = {...settings.theme, ...(usedTenant.settings.theme ?? {})};
        result.titles = {...settings.titles, ...(usedTenant.settings.titles ?? {})};
        result.links = {...settings.links, ...(usedTenant.settings.links ?? {})};
        result.mails = {...settings.mails, ...(usedTenant.settings.mails ?? {})};

        return result;
    }

    React.useEffect(() => {
        if (!applyGlobally) return;
        if (!useCurrent && !tenant) return;
        if (useCurrent && !currentTenant) return;

        const settings = getSettings();

        if (!settings) return;

        const theme = settings?.theme;

        if (!theme) return;
        if (theme.primaryColor === "#000000") return;
        
        document.documentElement.style.setProperty(`--$"primary"`, theme.primaryColor);

    }, [tenant]);

    return getSettings();
}