import useSWR from "swr";
import { ApiRoutes } from "../../../api/ApiRoutes";
import { IClient } from "../../../types/ApiTypes";
import { ICollection } from "../../../types/collection.types";

export const useClients = () => {
    const { data, isLoading, mutate } = useSWR<ICollection<IClient>>(ApiRoutes.Clients.All);

    return {
        clients: data?.items ?? [],
        clientsById: data?.byId ?? {},
        loadingClients: isLoading,
        reloadClients: mutate
    }
}