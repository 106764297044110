import React from "react";
import { useUsers } from "../state/swr/user/useUsers";
import { IUser } from "../types/ApiTypes";
import useUserUtil from "./useUserUtil";
import { useTeams } from "../state/swr/teams/useTeams";

export interface IWithFilteredUsers {
    displayed?: DisplayedUsers,
    showWithoutRole?: boolean
}
export type DisplayedUsers = "all" | "clients" | "teamMembers" | "employees";

export default function useFilteredUsers({displayed = "all", showWithoutRole = false}: IWithFilteredUsers) {
    
    const [filteredUsers, setFilteredUsers] = React.useState<Array<IUser>>([]);

    const {
        users,
        loadingUsers
    } = useUsers();

    const {
        teams
    } = useTeams();

    const {
        getRole
    } = useUserUtil();

    const getFilteredUsers = (): Array<IUser> => {
        if (displayed === "all") return users;

        const employees = users.filter(u => {
            const role = getRole(u);
            if (!role) return showWithoutRole;
            return role.isClient === (displayed === "clients");
        });

        if (displayed === "employees") return employees;

        if (!teams || !teams.length) return [];

        const teamMembers = employees.filter(u => teams.some(t => t.members.some(m => m._id === u._id)));

        return teamMembers;
    }

    React.useEffect(() => {
        if (loadingUsers) return;
        if (!users || !users.length) return;

        const u = getFilteredUsers();
        setFilteredUsers(u);
    }, [users, loadingUsers, displayed, showWithoutRole, teams]);
    
    return {
        loading: loadingUsers,
        users: filteredUsers
    }
}