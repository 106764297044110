import React from "react";
import { useRoles } from "../../state/swr/roles/useRoles";
import WithPermissions from "./WithPermissions";
import Flex from "../container/Flex";
import LoadingSpinner from "../loader/LoadingSpinner";
import Table from "../tables/Table";
import TableRow from "../tables/TableRow";
import TableCell from "../tables/TableCell";
import UpdateRoleForm from "./UpdateRoleForm";
import Typography from "../text/Typography";
import RoleBanner from "../user/RoleBanner";
import DeleteRoleButton from "./DeleteRoleButton";

export default function PermissionManagement() {

    const {
        roles,
        loadingRoles,
        reloadRoles
    } = useRoles();

    return (
        <WithPermissions permissions={[ "roles.all.read" ]}>
            <Flex fullWidth fullHeight>
                {
                    loadingRoles
                    ? <LoadingSpinner />
                    : (
                        roles && roles.length
                        ? (
                            <Table
                                headers={[
                                    { label: "Name", valueKey: "displayName" },
                                    { label: "Mandantenrolle", valueKey: "isClient" },
                                    { label: "Berechtigungslevel", valueKey: "accessLevel" },
                                    { label: "" }
                                ]}
                                items={roles}
                                renderItem={r => (
                                    <TableRow>
                                        <TableCell>
                                            <Typography bold>{r.displayName}</Typography>
                                            <RoleBanner role={r} />
                                        </TableCell>
                                        <TableCell value={r.isClient} />
                                        <TableCell>
                                            <Typography bold color="primary">{r.accessLevel}</Typography>
                                        </TableCell>
                                        <TableCell>
                                            <Flex row>
                                                <UpdateRoleForm role={r} />
                                                { !r.isInitialRole && <DeleteRoleButton role={r} /> }
                                            </Flex>
                                        </TableCell>
                                    </TableRow>
                                )}
                            />
                        )
                        : <span>Keine Rollen</span>
                    )
                }
            </Flex>
        </WithPermissions>
    )
}