import React from "react";
import { ITeam, ITenant, IUser } from "../../types/ApiTypes";
import { formatDate } from "../../util/formatter";
import TableCell from "../tables/TableCell";
import TableRow from "../tables/TableRow";
import UserRoleIndicator from "../user/RoleBanner";
import TeamMemberList from "./TeamMemberList";
import TeamUpdateForm from "./TeamUpdateForm";
import TeamUpdateMembersFromResponsibilityButton from "./TeamUpdateMembersFromResponsibilityButton";
import Pill from "../pills/Pill";
import Icon from "../icons/Icon";
import MailAccount from "../mail/MailAccount";
import TeamBanner from "./TeamBanner";
import Flex from "../container/Flex";
import { useUsers } from "../../state/swr/user/useUsers";
import LoadingSpinner from "../loader/LoadingSpinner";


export default function TeamsListItem({team}: {team: ITeam}) {

    const {
        usersById,
        loadingUsers
    } = useUsers();

    return (
        <TableRow>
            <TableCell>
                <Flex gap={1}>
                    <TeamBanner team={team} />
                    {
                        team.areaOfResponsibility ? 
                        (
                            <Pill color="#330053">
                                <Icon icon="star" />
                                <span>{team.areaOfResponsibility.name}</span>
                            </Pill>
                        )
                        : <span>-</span>
                    }
                </Flex>
            </TableCell>
            <TableCell>
                {
                    team.supervisor 
                    ? (
                        loadingUsers 
                        ? <LoadingSpinner />
                        : <UserRoleIndicator user={usersById[team.supervisor]} displayNameAsValue />
                    )
                    : <span>-</span>
                }
            </TableCell> 
            <TableCell>
                <TeamMemberList members={team.members} />
            </TableCell>
            <TableCell>
                <Flex row wrap justify="end">
                    <TeamUpdateMembersFromResponsibilityButton team={team} />
                    <TeamUpdateForm team={team} />
                </Flex>
            </TableCell>
        </TableRow>
    )
}
