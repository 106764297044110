import React from "react";
import { generateClassName } from "../../../hooks/useAttributes";
import { useTicketSidebarHistory } from "../../../hooks/useTicketSidebarHistory";
import { ITicket, ITicketMessage } from "../../../types/ApiTypes";
import { formatDate } from "../../../util/formatter";
import SidebarHeader from "../../sidebar/SidebarHeader";
import TicketMessageText from "../ticketChat/TicketMessageText";
import "./TicketMessageHistoryTab.css";

export default function TicketMessageHistoryTab({ticket}: {ticket: ITicket}) {

    const [message, setMessage] = React.useState<ITicketMessage>();

    const {
        messageId
    } = useTicketSidebarHistory();

    React.useEffect(() => {
        if (!ticket || !ticket.messages || !ticket.messages.length) {
            setMessage(undefined);
            return;
        }

        const m = ticket.messages.find(m => m._id === messageId);

        if (!m) {
            setMessage(undefined);
            return;
        }

        setMessage(m);
    }, [messageId])

    if (!message) return <span>Kein Verlauf</span>

    return (
        <>
            <SidebarHeader text="Bearbeitungsverlauf" />
            <div className="d-flex flex-column gap-2 h-100" style={{overflowY: "auto"}}>
                {
                    message.content.map((v, index) => {

                        const className = generateClassName("d-flex flex-column message-history-item p-2 ps-3 pe-3", {
                            value: index === message.content.length - 1,
                            onTrue: "message-history-item-current"
                        })
                        return (
                            <div className={className} key={`ticket-chat-sidebar-history-item-${index}`}>
                                <strong>{formatDate(v.createdAt, true, true)}</strong>
                                <TicketMessageText message={message} text={v.text} />
                            </div>
                        )
                    })
                }
            </div>
        </>
    )
}