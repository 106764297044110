import React from "react";
import { teamsCreate, teamsUpdate } from "../../api/Api";
import { ITeamCreateRequest, ITeamUpdateRequest } from "../../api/ApiRequests";
import { AppColor } from "../../app/AppStyles";
import useApi from "../../hooks/useApi";
import { useAreasOfResponsibility } from "../../state/swr/areasOfResponsibilities/useAreasOfResponsibility";
import { useTeams } from "../../state/swr/teams/useTeams";
import { ITeam } from "../../types/ApiTypes";
import Button from "../buttons/Button";
import Card from "../card/Card";
import SearchableComboBox from "../comboBox/SearchableComboBox";
import Flex from "../container/Flex";
import ColorPicker from "../formik/ColorPicker";
import FieldWithLabel from "../formik/FormikField";
import LoadingSpinner from "../loader/LoadingSpinner";
import ModalForm from "../modal/ModalForm";
import WithPermissions from "../permissions/WithPermissions";
import UserPicker from "../user/UserPicker";
import TeamBanner from "./TeamBanner";
import TeamDeleteButton from "./TeamDeleteButton";
import UserSelect from "../user/UserSelect";
import UserSearchableComboBox from "../comboBox/UserSearchableComboBox";

interface ITeamUpdateForm {
    team?: ITeam
}

export default function TeamUpdateForm({team}: ITeamUpdateForm) {

    const { areasOfResponsibility, loadingAreasOfResponsibility } = useAreasOfResponsibility();
    const { reloadTeams } = useTeams();

    const callApi = useApi();

    const getMembers = () => {
        if (!team || !team.members || !team.members.length) return [];
        return team.members.map(m => m._id);
    }

    const formValues = (
        team 
        ? {
            id: team._id,
            name: team.name,
            color: team.color,
            mailAccountId: team.mailAccount?._id,
            supervisor: team.supervisor,
            areaOfResponsibilityId: team.areaOfResponsibility?._id,
            members: getMembers()
        } as ITeamUpdateRequest
        : {
            name: "",
            supervisor: "",
            color: "#FFFFFF",
            members: []
        } as ITeamCreateRequest
    )

    const buttonText = team ? "Bearbeiten" : "Team erstellen";
    const formText = team ? "Team bearbeiten" : "Neues Team";
    const buttonColor: AppColor = team ? "primary" : "success";
    const icon = team ? "pen" : "plus";

    return (
        <WithPermissions permissions={["teams.all.delete"]}>
            <ModalForm 
                title={formText} 
                button={<Button icon={icon} text={buttonText} color={buttonColor} />}
                initialValues={formValues}
                onSubmit={async (values) => {

                    const result = team
                    ? await callApi(teamsUpdate(values as ITeamUpdateRequest), true)
                    : await callApi(teamsCreate(values as ITeamCreateRequest), true);

                    if (!result) return false;

                    await reloadTeams();
                    return true;
                }}
                sidebar={(formik, close) => (
                    <Flex>
                        {
                            team && (
                                <TeamDeleteButton teamId={team._id} afterDelete={() => {
                                    close();
                                    reloadTeams();
                                }} />
                            )
                        }
                        <Button disabled={!formik.dirty} loading={formik.isSubmitting} type="submit" icon="save" text="Speichern" color="success" />
                    </Flex>
                )}
            >
                {
                    (formik, close) => {
                        return (
                            <Flex className="w-100 text-start d-flex flex-column gap-3">
                                <FieldWithLabel label="Name" name="name" className="w-100" />
                                <Flex gap={1}>
                                    <span>Team-Farbe</span>
                                    <Flex row>
                                        <ColorPicker name="color" />
                                        <TeamBanner team={formik.values} />
                                    </Flex>
                                </Flex>
                                <UserSelect
                                    label="Eskalations-Nutzer"
                                    onChange={v => formik.setFieldValue("supervisor", v)}
                                    value={formik.values.supervisor}
                                    displayed="employees"
                                />
                                <UserPicker 
                                    displayed="employees"
                                    label="Team-Mitglieder" 
                                    saveValues={v => formik.setFieldValue("members", v)}
                                    values={formik.values.members || []}
                                />
                                {
                                    team && (
                                        <Card color="muted">
                                            <strong>DATEV-Zuständigkeit</strong>
                                            {
                                                loadingAreasOfResponsibility
                                                ? <LoadingSpinner text="Zuständigkeiten werden geladen..." centered={false} />
                                                : (
                                                    areasOfResponsibility && areasOfResponsibility.length
                                                    ? (
                                                        <SearchableComboBox
                                                            values={areasOfResponsibility}
                                                            itemToId={r => r._id}
                                                            onItemClick={r => formik.setFieldValue("areaOfResponsibilityId", r._id)}
                                                            value={(formik.values as ITeamUpdateRequest).areaOfResponsibilityId}
                                                            itemToString={r => r.name}
                                                        />
                                                    )
                                                    : <span>Keine Zuständigkeiten verfügbar. Diese können in DATEV angelegt werden.</span>
                                                )
                                            }
                                        </Card>
                                    )
                                }
                            </Flex>
                        )
                    }
                }
            </ModalForm>    
        </WithPermissions>
    )
}