import React from "react";
import { useUser } from "../../state/swr/user/useUser";
import Page from "../../layout/Page";
import PageHeader from "../../components/label/PageHeader";
import TestSocketBtn from "../../components/testing/TestSocketBtn";

export default function Testing() {
    return (
        <Page limitMaxHeight={false} >
            <PageHeader text="Testing"/>
            <TestSocketBtn />
        </Page>
    )
}