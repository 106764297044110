import { Params } from "react-router-dom";
import { Locale } from "../locale/Locale";

export interface IAppRouteSearchParam {
    param: string,
    getWithValueRemoved: (existingParams: URLSearchParams) => string,
    createNewValue: (val: any, existingParams: URLSearchParams) => string,
    getCurrentValue: (searchParams: URLSearchParams) => any,
}

const getParam = (param: string): IAppRouteSearchParam => ({
    param: param,
    getWithValueRemoved: (existingParams: URLSearchParams) => {
        var params = new URLSearchParams(existingParams ? existingParams.toString() : "");
        params.delete(param);
        return params.toString();
    },
    getCurrentValue: (searchParams: URLSearchParams) => searchParams && searchParams.get(param),
    createNewValue: (val: any, existingParams: URLSearchParams): string => {
        var params = new URLSearchParams(existingParams ? existingParams.toString() : "");
        params.set(param, val);
        return params.toString();
    }
})
export const QueryParams = {
    ImportId: getParam("importId")
}

export interface IAppRouteParam {
    forPath: string,
    keyName: string,
    getPath: (path: string, val: string) => string,
    getValue: (params: Readonly<Params<string>>) => string | undefined
}

const getAppRouteParam = (keyName: string): IAppRouteParam => ({
    forPath: `:${keyName}`,
    keyName: keyName,
    getPath: (path, val): string => path.replace(`:${keyName}`, val),
    getValue: (params) => params[keyName]
})

export const AppRouteParams = {
    DocumentClearanceReminderId: getAppRouteParam("documentClearanceReminderId"),
    UserId: getAppRouteParam("userId"),
    VerificationToken: getAppRouteParam("verificationToken"),
    PasswordResetToken: getAppRouteParam("passwordResetToken"),
    TicketId: getAppRouteParam("ticketId")
}

export interface IAppRouteGetValueParam {
    param: IAppRouteParam,
    value: string
}

export interface IAppRoute {
    path: string,
    icon?: string,
    title?: string
}

const buildPath = (...parts: string[]) => {
    if (!parts || !parts.length) return "*";
    return `/${parts.join("/")}`;
}

export const AppRoutes = {
    Testing: {
        path: "/testing",
        icon: "rulers",
        title: Locale.titles.testing
    },
    RoleManagement: {
        path: "/roles",
        icon: "person-badge-fill",
        title: Locale.titles.roleManagement
    },
    MailTemplates: {
        path: "/mail-templates",
        icon: "envelope-fill",
        title: "Mailvorlagen"
    },
    MailManagement: {
        path: "/mail-addresses",
        icon: "envelope-fill",
        title: Locale.titles.mailManagement
    },
    GiveDocumentClearancePage: {
        path: buildPath("clearance", AppRouteParams.DocumentClearanceReminderId.forPath)
    },
    DocumentClearanceOverview: {
        path: buildPath("clearance"),
        icon: "file-earmark-fill",
        title: Locale.titles.documentClearance
    },
    AddresseeManagement: {
        path: "/addressees",
        icon: "pin-fill",
        title: Locale.titles.addresseeManagement
    },
    Feedback: {
        path: "https://www.tiggi.io/feedback-tiggi",
        title: Locale.titles.feedback,
        icon: "chat-fill"
    },
    Home: {
        path: "/dashboard",
        icon: "grid-fill",
        title: Locale.titles.dashboard
    },
    Tickets: {
        path: "/",
        icon: "ticket-fill",
        title: Locale.titles.tickets
    },
    Ticket: {
        path: buildPath("tickets", AppRouteParams.TicketId.forPath),
        title: "Ticket"
    },
    RequestTenantAccess: {
        path: "/request-onboarding",
        title: "Onboarding anfragen"
    },
    RequestPasswordReset: {
        path: "/reset-password",
        title: "Passwort zurücksetzen"
    },
    SetNewPassword: {
        path: buildPath("reset-password", AppRouteParams.VerificationToken.forPath),
        title: "Neues Passwort setzen"
    },
    LogIn: {
        path: "/log-in",
        title: Locale.titles.logIn
    },
    UserManagement: {
        path: "/users",
        icon: "people-fill",
        title: Locale.titles.userManagement
    },
    TenantManagement: {
        path: "/tenants",
        icon: "building",
        title: Locale.titles.tenantManagement
    },
    Billing: {
        path: "/billing",
        icon: "wallet-fill",
        title: Locale.titles.billing
    },
    Inbox: {
        path: "/inbox",
        icon: "inbox-fill",
        title: Locale.titles.inbox
    },
    TenantOnboarding: {
        path: buildPath("join", AppRouteParams.VerificationToken.forPath),
        title: Locale.titles.register
    },
    VerifyUser: {
        path: buildPath("verify", AppRouteParams.VerificationToken.forPath),
        title: Locale.titles.register
    },
    CurrentUserProfile: {
        path: "/profile",
        icon: "person-fill",
        title: Locale.titles.profile
    },
    Settings: {
        path: "/settings",
        icon: "gear-fill",
        title: Locale.titles.settings
    },
    ClientManagement: {
        path: "/clients",
        icon: "emoji-smile-fill",
        title: Locale.titles.clientContactManagement
    },
    ClientResponsibilityManagement: {
        path: "/clients/responsibilities",
        icon: "bounding-box",
        title: Locale.titles.clientResponsibilityManagement
    },
    TeamsManagement: {
        path: "/teams",
        icon: "diagram-2-fill",
        title: Locale.titles.teamsManagement
    },
    ClientCompanyManagement: {
        path: "/mandates",
        icon: "shop",
        title: Locale.titles.clientManagement
    },
    EmployeeManagement: {
        path: "/employees",
        icon: "cup-hot-fill",
        title: Locale.titles.employeeManagement
    },
    TenantDomainManagement: {
        path: "/domains/tenant",
        icon: "router-fill",
        title: Locale.titles.tenantDomainManagement
    },
    DomainManagement: {
        path: "/domains",
        icon: "router-fill",
        title: Locale.titles.domainManagement
    },
    Logs: {
        path: "/logs",
        icon: "journal-x",
        title: Locale.titles.logs
    },
    MailLogs: {
        path: "/logs/mail",
        icon: "envelope-fill",
        title: Locale.titles.mailLog
    },
    ServiceManagement: {
        path: "/services",
        icon: "credit-card-2-front-fill",
        title: Locale.titles.serviceManagement
    },
}

export const AnyRoute = "*";
