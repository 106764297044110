import { ITicket, ITicketActivity, TicketActivityType, TicketSource } from "../types/ApiTypes";

export default function useTicketActivityUtil() {
    const getIcon = (activity: ITicketActivity) => {
        if (!activity) return "";

        switch (activity.activityType) {
            case TicketActivityType.Archived: return "archive";
            case TicketActivityType.Assigned: return "person";
            case TicketActivityType.Unassigned: return "person-dash";
            case TicketActivityType.AssignedToTeam: return "people";
            case TicketActivityType.Closed: return "x";
            case TicketActivityType.Created: return "plus";
            case TicketActivityType.Escalated: return "chevron-up";
            case TicketActivityType.InternalSubjectChanged: return "pen";
            case TicketActivityType.Merged: return "layers";
            case TicketActivityType.MessageAdded: return "envelope";
            case TicketActivityType.MessageUpdated: return "pen";
            case TicketActivityType.NoteAdded: return "sticky";
            case TicketActivityType.Reopened: return "check";
            case TicketActivityType.Solved: return "check";
            case TicketActivityType.ClientChanged: return "house";
        }
    }

    const getContent = (activity: ITicketActivity, ticket?: ITicket) => {
        
        if (!activity) return "";

        switch (activity.activityType) {
            case TicketActivityType.Archived: return "hat das Ticket archiviert.";
            case TicketActivityType.Unassigned: return "hat einen Nutzer aus der Zuweisung entfernt.";

            case TicketActivityType.Assigned: return "hat das Ticket einem Nutzer zugewiesen.";
            case TicketActivityType.AssignedToTeam: return "hat das Ticket einem Team zugewiesen.";
            case TicketActivityType.Closed: return "hat das Ticket geschlossen.";
            case TicketActivityType.Created: 

                if (!ticket) return "hat das Ticket eröffnet.";
                
                switch (ticket.source) {
                    case TicketSource.Android: return "hat das Ticket über die Android-App eröffnet.";
                    case TicketSource.iOS: return "hat das Ticket über die iOS-App eröffnet.";
                    case TicketSource.Mail: return "hat das Ticket per E-Mail eröffnet.";
                    case TicketSource.ManualFromMail: return "hat das Ticket aus einer E-Mail eröffnet.";
                    case TicketSource.Web: return "hat das Ticket über die Webseite eröffnet.";
                }
            
            case TicketActivityType.Escalated: return "hat das Ticket eskaliert.";
            case TicketActivityType.ClientChanged: return "hat das Ticket einem Mandanten zugewiesen.";
            case TicketActivityType.InternalSubjectChanged: return "hat den internen Betreff geändert.";
            case TicketActivityType.Merged: return "hat das Ticket zusammengeführt.";

            case TicketActivityType.MessageAdded: return "hat eine Nachricht hinzugefügt.";
            case TicketActivityType.MessageUpdated: return "hat eine Nachricht bearbeitet.";
            case TicketActivityType.NoteAdded: return "hat eine Notiz hinzugefügt.";
            case TicketActivityType.Reopened: return "hat das Ticket wiedereröffnet.";
            case TicketActivityType.Solved: return "hat das Ticket gelöst.";
        }
    }

    return {
        getContent,
        getIcon
    }
}