import React from "react";
import { SWRConfig } from "swr";
import { swrFetch } from "../api/Api";

export default function SWRProvider({children}: {children: any}) {
    return (
        <SWRConfig
            value={{
                fetcher: swrFetch,
                revalidateOnFocus: false,
                revalidateIfStale: false,
                revalidateOnReconnect: false
            }}
        >
            {
                children
            }
        </SWRConfig> 
    )
}