import { FieldArray, FormikProps } from "formik";
import React from "react";
import { IApiResponse, usersAddAlias, usersCreateNew, usersUpdate, usersUpdateClientAccess, usersUpdateDefaultTeam } from "../../api/Api";
import { ICreateAliasRequest, IUpdateUserClientsRequest, IUserUpdateDefaultTeamRequest } from "../../api/ApiRequests";
import { AppColor } from "../../app/AppStyles";
import useApi from "../../hooks/useApi";
import { generateClassName } from "../../hooks/useAttributes";
import useTeamsUtil from "../../hooks/useTeamsUtil";
import useUserUtil from "../../hooks/useUserUtil";
import { Locale } from "../../locale/Locale";
import { useClientContacts } from "../../state/swr/clientContacts/useClientContacts";
import { useClients } from "../../state/swr/clients/useClients";
import { useEmployees } from "../../state/swr/employees/useEmployees";
import { useAnyUserAlias } from "../../state/swr/user/useAnyUserAlias";
import { useAnyUserClients } from "../../state/swr/user/useAnyUserClients";
import { useAnyUserDefaultTeam } from "../../state/swr/user/useAnyUserDefaultTeam";
import { useUser } from "../../state/swr/user/useUser";
import { useUsers } from "../../state/swr/user/useUsers";
import { IClient, IPermission, IUser, Permission } from "../../types/ApiTypes";
import AliasForm from "../alias/AliasForm";
import Button from "../buttons/Button";
import SearchableComboBox from "../comboBox/SearchableComboBox";
import Flex from "../container/Flex";
import CheckBox from "../formik/CheckBox";
import FieldWithLabel from "../formik/FormikField";
import Icon from "../icons/Icon";
import ModalForm from "../modal/ModalForm";
import WithPermissions from "../permissions/WithPermissions";
import TabSwitcher from "../tabswitcher/TabSwitcher";
import Typography from "../text/Typography";
import DeleteUserButton from "./DeleteUserButton";
import UserAuthorityFieldArray from "./UserAuthorityFieldArray";
import "./UserUpdateForm.css";

interface IUserUpdateFormProps {
    user?: IUser,
    text?: string,
    title?: string,
    icon?: string,
    color?: AppColor,
    creationBase?: Partial<IUser>,
    isClientContact?: boolean,
    generalFieldsAreDisabled?: boolean,
}

enum UserUpdateTab {
    General = "general",
    Teams = "teams",
    Clients = "clients",
    Alias = "alias"
}

export default function UserUpdateForm({user, color, text, creationBase, title, icon, isClientContact = false, generalFieldsAreDisabled = false}: IUserUpdateFormProps) {

    const isCreate = !user;

    const { defaultTeam, loadingDefaultTeam, reloadDefaultTeam } = useAnyUserDefaultTeam(user ? user._id : "");
    const { loadingUserClients, reloadUserClients, userClients } = useAnyUserClients(user ? user._id : "");
    const { loadingUserAlias, reloadUserAlias, userAlias } = useAnyUserAlias(user ? user._id : "");

    const [tab, setTab] = React.useState<UserUpdateTab>(UserUpdateTab.General);

    const { clients } = useClients();

    const { reloadClientContacts } = useClientContacts()
    const { reloadEmployees } = useEmployees();
    const { reloadUsers } = useUsers();
    const { user: currentUser, reloadUser }= useUser();

    const {
        normalizeUser
    } = useUserUtil();

    const {
        getCurrentTenantTeams
    } = useTeamsUtil();

    const callApi = useApi();

    const getSubmitText = () => {

        if (isCreate) return Locale.pages.userManagement.newUser;

        switch (tab) {
            case UserUpdateTab.General: return Locale.pages.userManagement.updateUserButton;
            case UserUpdateTab.Teams: return "Standard-Team speichern";
            case UserUpdateTab.Clients: return "Mandantenzugriff speichern";
            case UserUpdateTab.Alias: return "Alias erstellen";
        }
    }

    const getSubmitColor = () => {
        if (isCreate) return "success";

        return "primary";
    }
    
    const buttonText = text || (isCreate ? Locale.pages.userManagement.newUser : Locale.pages.userManagement.updateUserButton);
    const buttonColor = color || (isCreate ? "success" : "primary");
    const formTitle = title || (isCreate ? Locale.pages.userManagement.addUser : Locale.pages.userManagement.updateUser);
    const buttonIcon = icon || (isCreate ? "person-plus" : "pen");

    const realUser = normalizeUser(user || creationBase, true);

    const availableTeams = getCurrentTenantTeams();

    if (!user && !isCreate) return null;

    const getFormValues = () => {
        if (!user || isCreate) return realUser;

        switch (tab) {
            case UserUpdateTab.General: return realUser;
            case UserUpdateTab.Teams: return {
                id: user._id,
                defaultTeam: defaultTeam ? defaultTeam.defaultTeam : null
            } as IUserUpdateDefaultTeamRequest;
            case UserUpdateTab.Clients: return {
                id: user._id,
                clients: userClients ? userClients.clients : [],
                defaultClient: userClients ? userClients.defaultClient : undefined
            } as IUpdateUserClientsRequest;
            case UserUpdateTab.Alias: return {
                id: user._id,
                aliasMailAddress: ""
            } as ICreateAliasRequest;
        }
    }

    const getPermission = (key?: keyof IPermission): Permission => {
        const realKey = key ? key : (isCreate ? "create" : "update");
        if (isClientContact) return `users.clientContacts.${realKey}`;
        else return `users.employees.${realKey}`;
    }

    return (
        <WithPermissions permissions={[getPermission()]}>
            <ModalForm 
                title={formTitle} 
                closeAfterSubmit={tab !== UserUpdateTab.Alias}
                enableReinitialize
                button={<Button icon={buttonIcon} text={buttonText} color={buttonColor} />}
                initialValues={getFormValues()}
                onSubmit={async (values) => {

                    let result: IApiResponse | null = null;

                    if (isCreate || tab === UserUpdateTab.General) {
                        if (isCreate) result = await callApi<any>(usersCreateNew(values as IUser));
                        else result = await callApi<any>(usersUpdate(values as IUser));
                    }
                    else if (tab === UserUpdateTab.Alias) result = await callApi(usersAddAlias(values as ICreateAliasRequest));
                    else if (tab === UserUpdateTab.Teams) result = await callApi(usersUpdateDefaultTeam(values as IUserUpdateDefaultTeamRequest));
                    else if (tab === UserUpdateTab.Clients) result = await callApi(usersUpdateClientAccess(values as IUpdateUserClientsRequest));

                    if (!result || !result.success) return false;
        
                    await reloadUsers();
                    await reloadEmployees();
                    await reloadClientContacts();
                    
                    await reloadDefaultTeam();
                    await reloadUserClients();
                    await reloadUserAlias();

                    return true;
                }}
                sidebar={(formik, close) => (
                    <Flex fullWidth justify="between">
                        <WithPermissions permissions={[ getPermission("delete") ]}>
                            {
                                !isCreate && user && tab === UserUpdateTab.General && (
                                    <div className="d-flex flex-row align-items-center gap-3 mb-3 mt-4">
                                        <DeleteUserButton userId={user._id} afterDelete={() => {
                                            close();
                                            reloadUsers();
                                        }} />
                                    </div>
                                )
                            }
                        </WithPermissions>
                        <Button disabled={!formik.dirty} loading={formik.isSubmitting} icon="save" loadingText="Bitte warten..." type="submit" text={getSubmitText()} className="float-end" color={getSubmitColor()} />
                    </Flex>
                )}
            >
                {
                    (formik: FormikProps<any>) => {                  
                        const getContent = () => {
                            const content = (
                                <Flex className="w-100" gap={3}>
                                    <Typography color="primary" bold>Allgemein</Typography>
                                    <Flex row fullWidth>
                                        <FieldWithLabel readOnly={generalFieldsAreDisabled} className="w-100" label="Titel" name="title" placeholder="z.B. Doktor, Steuerberater, WP, RA..."  />
                                        {
                                            !isClientContact && <FieldWithLabel readOnly={generalFieldsAreDisabled} className="w-100" label="Prefix für Signatur" name="mailNamePrefix" placeholder="z.B. in Vertretung, i.V., i.A." />
                                        }
                                    </Flex>
                                    <FieldWithLabel readOnly={generalFieldsAreDisabled} className="w-100" label="Vorname" name="firstName" />
                                    <FieldWithLabel readOnly={generalFieldsAreDisabled} className="w-100" label="Nachname" name="lastName" />
                                    <FieldWithLabel readOnly={generalFieldsAreDisabled} className="w-100" label="E-Mail" name="mailAddress" />
                                    {
                                        !generalFieldsAreDisabled && <UserAuthorityFieldArray isCreate={isCreate} isClientContact={isClientContact} />
                                    }
                                    {
                                        !isClientContact && currentUser && currentUser.isSuperAdmin && (
                                            <div className="d-flex flex-column gap-2 mb-2 mt-3">
                                                <Typography color="primary" bold>Sonderrechte</Typography>
                                                <Flex row wrap className="w-100">
                                                    {
                                                        currentUser && currentUser.isSuperAdmin && <CheckBox name="isSuperAdmin" label="Globaler Administrator" />
                                                    }
                                                    {
                                                        currentUser && (currentUser.isSuperAdmin || currentUser.isDeveloper) && <CheckBox name="isDeveloper" label="Entwickler-Zugriff" />
                                                    }
                                                    {
                                                        currentUser && (currentUser.isSuperAdmin || currentUser.isDeveloper) && <CheckBox name="isTestingUser" label="Testnutzer (Ignoriert Einstellungen zu Mailversand)" />
                                                    }
                                                </Flex>
                                            </div>
                                        )
                                    }
                                </Flex>
                            );
        
                            if (isCreate) return content;

                            switch (tab) {
                                case UserUpdateTab.General: return content;
                                case UserUpdateTab.Teams: return (
                                    <SearchableComboBox 
                                        disabled={!availableTeams || !availableTeams.length}
                                        values={availableTeams}
                                        value={formik.values.defaultTeam}
                                        itemToId={(t) => t._id}
                                        className="mb-3 w-100"
                                        itemToString={(t) => t.name}
                                        label="Standard-Team" 
                                        onItemClick={(t) => formik.setFieldValue("defaultTeam", t)}
                                    />
                                );
                                case UserUpdateTab.Clients: 
                                
                                    const getAvailableClients = () => {
                                        if (!clients || !clients.length) return [];
                                        if (!userClients || !userClients.clients || !userClients.clients.length) return clients;
                                
                                        return clients.filter(c => !userClients.clients!.find(e => e._id === c._id));
                                    }
                                
                                    const availableClients = getAvailableClients();
                                
                                    const canBeAssignedToMoreClients =  availableClients && availableClients.length;
        
                                    return (
                                        <Flex className="w-100">
                                            <FieldArray name="clients">
                                                {
                                                    (arrayHelpers) => {
                                                        return (
                                                            <div className="user-clients-field-array-container d-flex flex-column align-items-start w-100">
                                                                <div className="d-flex align-items-start justify-content-between w-100 mb-1">
                                                                    <strong>Zugewiesene Mandanten</strong>
                                                                    <Button 
                                                                        text={canBeAssignedToMoreClients ? "Weiterer Mandant" : "Allen Mandanten zugewiesen"} 
                                                                        icon="plus" 
                                                                        color="primary" 
                                                                        disabled={!canBeAssignedToMoreClients} 
                                                                        onClick={async () => arrayHelpers.push({
                                                                            name: ""
                                                                        } as IClient)} 
                                                                    />
                                                                </div>
                                                                <div className="user-clients-field-array d-flex flex-column gap-2 w-100">
                                                                    {
                                                                        formik.values.clients && formik.values.clients.length 
                                                                        ? formik.values.clients.map((c: IClient, index: number) => (
                                                                            <UserAssignedClient 
                                                                                key={c._id || `user-authority-clients-field-${index}`} 
                                                                                client={c} 
                                                                                clientIndex={index} 
                                                                                clients={availableClients} 
                                                                                setAsDefault={() => formik.setFieldValue("defaultClient", c)} 
                                                                                defaultClient={formik.values.defaultClient} 
                                                                                deleteValue={() => arrayHelpers.remove(index)} 
                                                                                saveValue={(c) => formik.setFieldValue(`clients.[${index}]`, c)} 
                                                                            />
                                                                        ))
                                                                        : <em>Kein Mandant zugewiesen.</em>
                                                                    }
                                                                </div>
                                                            </div>
                                                        )
                                                    }
                                                }
                                            </FieldArray>
                                        </Flex>
                                    );
                                case UserUpdateTab.Alias: return <AliasForm data={userAlias} mutate={reloadUserAlias} isLoading={loadingUserAlias} />;
                            }
                        }

                        return (
                            <Flex className="w-100 h-100">
                                {
                                    !isCreate && (
                                        <TabSwitcher
                                            saveActiveTab={(t) => setTab(t as UserUpdateTab)}
                                            tabQueryParamKey="view"
                                            tabs={[
                                                { data: UserUpdateTab.General, label: "Allgemein" },
                                                { data: UserUpdateTab.Teams, label: "Teams", hidden: isClientContact, permissions: [ "teams.all.update"]},
                                                { data: UserUpdateTab.Clients, label: "Mandantenzugriff", hidden: !isClientContact, permissions: [ "employeeResponsibilities.all.update" ] },
                                                { data: UserUpdateTab.Alias, label: "Alias", hidden: !isClientContact, permissions: [ "alias.all.update" ]}
                                            ]}
                                        />
                                    )
                                }
                                {getContent()}
                            </Flex>
                        )
                    }
                }
            </ModalForm>    
        </WithPermissions>
    )
}


interface IUserAssignedClientProps {
    client: IClient, 
    defaultClient?: IClient, 
    deleteValue: () => void, 
    setAsDefault: () => void,
    saveValue: (c: IClient) => void, 
    clients: IClient[], 
    clientIndex: number
}

function UserAssignedClient({client, setAsDefault, deleteValue, saveValue, defaultClient, clients, clientIndex}: IUserAssignedClientProps) {
    
    const isDefaultClient = (defaultClient && defaultClient._id === client._id);
    const defaultClientIcon = isDefaultClient ? "star-fill" : "star";

    const className = generateClassName("d-flex p-2 user-clients-field-array-client flex-row align-items-center w-100 gap-2", {
        value: isDefaultClient,
        onTrue: "user-clients-field-array-client-favorite"
    });

    return (
        <div className={className} key={client._id || `user-clients-field-array-item-${clientIndex}`}>
            <Icon onClick={setAsDefault} icon={defaultClientIcon} className="user-clients-field-array-favorite-icon" size={16} /> 
            <SearchableComboBox 
                placeholder="Mandant auswählen..."
                resetValueOnClick
                values={clients} 
                className="w-100"
                onItemClick={saveValue}
                value={client}
                renderValue={(c) => <span>{c.name}{isDefaultClient && " (Standard)"}</span>}
                itemToId={(c: IClient) => c._id} 
                itemToString={(c: IClient) => c.name} 
            />
            <Icon onClick={deleteValue} icon="x" size={16} /> 
        </div>
    )
}