import React from "react";
import { useAreasOfResponsibility } from "../state/swr/areasOfResponsibilities/useAreasOfResponsibility";
import { useClients } from "../state/swr/clients/useClients";
import { useDatevEmployees } from "../state/swr/employees/useDatevEmployees";
import { useEmployeeResponsibilities } from "../state/swr/employees/useEmployeeResponsibilities";
import { IClient, IDatevAreaOfResponsibility, IEmployee } from "../types/ApiTypes";
import useClientResponsibilityUtil from "./useClientResponsibilityUtil";

export interface ISortedClientResponsibility {
    areaOfResponsibility: IDatevAreaOfResponsibility,
    employees: Array<IEmployee>
}

export interface ISortedClientResponsibilities {
    client: IClient,
    responsibilities: Array<ISortedClientResponsibility>
}

export default function useClientResponsibility() {
    const [clientResponsibility, setClientResponsibility] = React.useState<Array<ISortedClientResponsibilities>>([]);
    
    const {
        sortClientResponsibility
    } = useClientResponsibilityUtil();

    const { areasOfResponsibility, loadingAreasOfResponsibility } = useAreasOfResponsibility();
    const { employeeResponsibilities, loadingEmployeeResponsibilities} = useEmployeeResponsibilities();
    const { clients, loadingClients } = useClients();
    const { datevEmployees, loadingDatevEmployees } = useDatevEmployees();

    const loading = loadingAreasOfResponsibility || loadingEmployeeResponsibilities || loadingClients || loadingDatevEmployees;

    React.useEffect(() => {
        if (loading) return;
        const result = sortClientResponsibility(clients || [], areasOfResponsibility || [], datevEmployees || [], employeeResponsibilities || []);
        setClientResponsibility(result);
    }, [datevEmployees, areasOfResponsibility, clients, employeeResponsibilities]);

    
    return {
        loading: loading,
        sortedClientResponsibility: clientResponsibility,
        rawResponsibilities: employeeResponsibilities
    }
}