import React from "react";
import { generateClassName, generateStyle } from "../../hooks/useAttributes";
import Typography from "../text/Typography";
import "./Tooltip.css";

export interface IWithTooltip {
    tooltip?: string,
}

export interface ITooltipProps extends IWithTooltip {
    children: React.ReactNode,
    className?: string
}

export interface IIconTooltipOrigin {
    top: number,
    left: number,
}

export default function Tooltip({children, tooltip, className}: ITooltipProps) {

    const [hover, setHover] = React.useState<boolean>(false);
    const [tooltipOrigin, setTooltipOrigin] = React.useState<IIconTooltipOrigin>();

    const tooltipRef = React.useRef<HTMLDivElement>(null);
    const hoverTimeout = React.useRef<any>();

    const getTooltipOrigin = () => {
        if (!tooltipRef.current) return;

        const viewportCenter = {
            x: (document?.documentElement?.clientWidth ?? 0) / 2,
            y: (document?.documentElement?.clientHeight ?? 0) / 2
        }

        const rect = tooltipRef.current.getBoundingClientRect();

        const top = (
            rect.top < viewportCenter.y || viewportCenter.y <= 0
            ? (rect.top + (rect.height / 2)) + 14 
            : (rect.top - 14)
        )

        const left = (
            rect.left < viewportCenter.x || viewportCenter.x <= 0
            ? rect.left + rect.width
            : rect.left - rect.width
        )

        setTooltipOrigin({
            top,
            left
        });
    }

    const toggleHover = () => {
        if (!tooltip) return;

        getTooltipOrigin();
        clearTimeout(hoverTimeout.current);

        hoverTimeout.current = setTimeout(() => {
            setHover(false);
        }, 1000);

        setHover(true);
    }

    const tooltipClass = generateClassName("tooltip-container d-flex flex-row align-items-center justify-content-center p-1 ps-2 pe-2");

    const wrapperClass = generateClassName(className, "position-relative");
    
    return (
        <div 
            ref={tooltipRef}
            className={wrapperClass}
            onMouseEnter={toggleHover}
            onMouseLeave={() => setHover(false)}
        >
            {children}
            {
                hover && tooltip && tooltipOrigin && (
                    <div 
                        className={tooltipClass}
                        style={generateStyle({
                            name: "left",
                            value: tooltipOrigin.left - 30,
                            unit: "px"
                        }, {
                            name: "top",
                            value: tooltipOrigin.top + 5,
                            unit: "px"
                        })}
                    >
                        <Typography size={12}>{tooltip}</Typography>
                    </div>
                )
            }
        </div>
    )
}