import { IMailTemplateElement, MailTemplate, MailTemplateElementType } from "../types/ApiTypes";

export const useMailTemplateElements = () => {
    const getStringified = (values: Array<IMailTemplateElement>) => {
        if (!values || !values.length) return "";

        return values.map(v => {
            if (v.type === MailTemplateElementType.LineBreak) return "\n\n";
            return v.content
        }).join(" ");
    }

    const getDisplayValueForType = (type: MailTemplate) => {
        switch (type) {
            case MailTemplate.DocumentClearanceReminderTextOnly: return "Dokumentenfreigabe Erinnerung (Nur Text)";
            case MailTemplate.DocumentClearanceReminderWithLink: return "Dokumentenfreigabe Erinnerung (Mit Link)";
            case MailTemplate.TicketAssigned: return "Ticketzuweisung";
            case MailTemplate.TicketClosed: return "Ticket geschlossen";
            case MailTemplate.TicketCreated: return "Ticket erstellt";
            case MailTemplate.TicketDeleted: return "Ticket gelöscht";
            case MailTemplate.TicketEscalated: return "Ticket eskaliert";
            case MailTemplate.TicketEscalationMailToClient: return "Ticket-Eskalation Benachrichtigung an Kunde";
            case MailTemplate.TicketEscalationMailToSupervisor: return "Ticket-Eskalation Benachrichtigung an Vorgesetzten";
            case MailTemplate.TicketReopened: return "Ticket wiedereröffnet";
            case MailTemplate.TicketMessage: return "Ticket-Nachricht";
            case MailTemplate.TicketPdf: return "Ticket-PDF";
            case MailTemplate.TicketSolved: return "Ticket gelöst";
            case MailTemplate.TicketSetToResubmission: return "Ticket auf Wiedervorlage";
            case MailTemplate.TicketUpdated: return "Ticket aktualisiert";
            case MailTemplate.NewEmployeeInvitation: return "Mitarbeiter-Einladung (Onboarding)";
            case MailTemplate.TicketMention: return "Erwähnung in Ticketnachricht";
        }
    }
    
    return {
        getStringified,
        getDisplayValueForType
    }
}