import React from "react";
import Expandable from "../comboBox/Expandable";
import UserAvatar from "./profile/UserAvatar";
import { useAppSelector } from "../../hooks/reduxHooks";
import ExpandableItem from "../comboBox/ExpandableItem";
import { AppRoutes, IAppRoute } from "../../config/AppRoutes";
import { useUser } from "../../state/swr/user/useUser";
import Typography from "../text/Typography";
import Divider from "../sidebar/Divider";
import { hexWithOpacity } from "../../util/color";
import Flex from "../container/Flex";
import useUserUtil from "../../hooks/useUserUtil";
import "./UserMenu.css";
import { useNavigate } from "react-router-dom";
import Icon from "../icons/Icon";
import { useSession } from "../../state/swr/session/useSession";
import useApi from "../../hooks/useApi";
import { userLogOut } from "../../api/Api";
import { Cookies, deleteCookie } from "../../util/cookies";
import RoleBanner from "./RoleBanner";

export default function UserMenu() {

    const { reloadSession } = useSession();

    const callApi = useApi();

    const logOut = async () => {
        await callApi(userLogOut());
        deleteCookie(Cookies.Session);
        reloadSession(undefined, { revalidate: true });
    }

    const [expanded, setExpanded] = React.useState<boolean>(false);

    const { user } = useUser();
    const { getName } = useUserUtil();

    return (
        <div 
            onMouseLeave={() => setExpanded(false)}
            onClick={() => setExpanded(true)}
            className="position-relative user-menu-container"
        >
            <Flex className="user-menu-avatar" onClick={() => setExpanded(false)}>
                <UserAvatar user={user} mailAddress={user ? user.mailAddress : "xy@test.de"} />
            </Flex>
            {
                expanded && (
                    <Flex className="user-menu pb-2 position-absolute">
                        <Flex row justify="between" className="ps-3" fullWidth>
                            <Flex gap={0}>
                                <Typography color="primary" bold>{ getName(user) }</Typography>
                                <Typography size={9} >{ user?.mailAddress }</Typography>
                            </Flex>
                            <UserAvatar user={user} mailAddress={user ? user.mailAddress : "xy@test.de"} />
                        </Flex>
                        <Divider height={1} color="muted" opacity={0.2} />
                        <UserMenuItem to={AppRoutes.CurrentUserProfile} text="Profil" icon="person-fill" />
                        <UserMenuItem to={AppRoutes.Settings} text="Einstellungen" icon="gear-fill" />
                        <Divider height={1} color="muted" opacity={0.2} />
                        <UserMenuItem
                            text="Abmelden"
                            icon="power"
                            onClick={logOut} 
                        />
                    </Flex>
                )
            }
        </div>
    )
}

function UserMenuItem({icon, text, to, onClick}: {to?: IAppRoute, text: string, icon: string, onClick?: () => Promise<void>}) {

    const [loading, setLoading] = React.useState<boolean>(false);
    const navigate = useNavigate();

    const clickHandler = async () => {
        if (to) {
            navigate(to.path);
            return;
        }

        if (!onClick) return;

        setLoading(true);
        await onClick();
        setLoading(false);
    }


    return (
        <Flex row onClick={clickHandler} className="user-menu-item ps-3 pe-3 pt-2 pb-2">
            <Flex align="center" justify="center"  className="user-menu-item-icon">
                <Icon size={22} loading={loading} icon={icon} color="muted" />
            </Flex>
            <Typography color="primary">{text}</Typography>
        </Flex>
    )
}