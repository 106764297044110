import useSWR from "swr";
import { ApiRoutes } from "../../../api/ApiRoutes";
import { MailTemplate } from "../../../types/ApiTypes";
import { ICollection } from "../../../types/collection.types";
import { IUsedMailTemplate } from "../../../types/usedMailTemplates.schema";

export const useUsedMailTemplates = () => {
    const { data, isLoading, mutate } = useSWR<ICollection<IUsedMailTemplate, MailTemplate>>(ApiRoutes.MailTemplates.Used.All);

    return {
        usedMailTemplatesById: data?.byId ?? {} as { [key in MailTemplate]: IUsedMailTemplate },
        usedMailTemplates: data?.items ?? [],
        loadingUsedMailTemplates: isLoading,
        reloadUsedMailTemplates: mutate
    }
}