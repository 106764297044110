import React from "react";
import { useAppDispatch, useAppSelector } from "../../hooks/reduxHooks";
import { generateClassName, generateStyle } from "../../hooks/useAttributes";
import { setActiveGroup } from "../../state/slices/sidebarSlice";
import { Permission } from "../../types/ApiTypes";
import IElementProps from "../../types/ElementProps";
import { hexWithOpacity } from "../../util/color";
import Icon from "../icons/Icon";
import WithPermissions from "../permissions/WithPermissions";
import SideNavElement from "./SideNavElement";
import "./SideNavGroup.css";
import { NavGroup } from "../../types/nav.types";

export interface ISideNavGroupProps extends IElementProps {
    isExpandedInitially?: boolean,
    displayCondition?: boolean,
    displayPermissions?: Permission[],
    label: string,
    groupId: NavGroup,
}

export default function SideNavGroup({children, className, style, displayPermissions,  displayCondition = true, label, isExpandedInitially = false, groupId}: ISideNavGroupProps) {
    
    const [hover, setHover] = React.useState<boolean>(false);
    const [isActive, setIsActive] = React.useState<boolean>();
    const [childrenMaxHeight, setChildrenMaxHeight] = React.useState<number>(0);
    
    const dispatch = useAppDispatch();
    const childrenContainerRef = React.useRef<HTMLTableSectionElement>(null);

    const {
        activeGroup
    } = useAppSelector(state => state.sidebar);

    React.useEffect(() => {
        setIsActive(activeGroup === groupId);
    }, [activeGroup]);
    
    React.useEffect(() => {

        if (!childrenContainerRef || !childrenContainerRef.current) return;

        const maxHeight = childrenContainerRef.current.clientHeight;

        if (!maxHeight) return;

        setChildrenMaxHeight(maxHeight + 12);

    }, [childrenContainerRef, children]);

    const childrenContainerClass = generateClassName(className, "side-nav-group-content pe-2 h-100 w-100", {
        value: isActive,
        base: "side-nav-group-content-",
        onTrue: "expanded",
        standard: "collapsed"
    });

    const childrenContainerStyle = generateStyle({
        name: "maxHeight",
        value: isActive ? childrenMaxHeight : 0,
        unit: "px"
    }, {
        name: "minHeight",
        value: isActive ? childrenMaxHeight : 0,
        unit: "px"
    })

    const iconClassName = generateClassName("side-nav-group-expander", {
        base: "side-nav-group-expander-",
        value: isActive,
        onTrue: "expanded",
        standard: "collapsed" 
    })

    const expanderClassName = generateClassName("side-nav-expander-container d-flex flex-row align-items-center justify-content-between", {
        value: isActive,
        onTrue: "side-nav-expander-container-expanded"
    });

    const clickHandler = () => {
        if (isActive) dispatch(setActiveGroup(NavGroup.None));
        else dispatch(setActiveGroup(groupId));
    }

    if (!displayCondition) return null;
    
    return (
        <WithPermissions permissions={displayPermissions}>
            <div 
                className="side-nav-group-container"
                style={generateStyle({
                    name: "backgroundColor",
                    value: hexWithOpacity("secondary", 0.2, true),
                    applyCondition: hover || isActive
                })}
                onMouseEnter={() => setHover(true)}
                onMouseLeave={() => setHover(false)}
            >
                <div onClick={clickHandler} className={expanderClassName}>
                    <SideNavElement label={label} />
                    <Icon icon="chevron-right" size={20} className={iconClassName} />
                </div>
                <div className={childrenContainerClass} style={childrenContainerStyle} >
                    <div ref={childrenContainerRef} className="d-flex flex-column gap-0 w-100">
                        {
                            React.Children.map(children, (child: any, index) => React.cloneElement(child, {parentGroup: groupId}))
                        }
                    </div>
                </div>
            </div>
        </WithPermissions>
    )
}