import React from "react";
import { mailTemplatesCreate, mailTemplatesUpdate } from "../../api/Api";
import useApi from "../../hooks/useApi";
import { useMailTemplateElements } from "../../hooks/useMailTemplateElements";
import { useGlobalMailTemplates } from "../../state/swr/templates/useGlobalMailTemplates";
import { useMailTemplates } from "../../state/swr/templates/useMailTemplates";
import { IGlobalMailTemplate, IMailTemplate, IMailTemplateDocument, MailTemplate } from "../../types/ApiTypes";
import Button from "../buttons/Button";
import Flex from "../container/Flex";
import ModalForm from "../modal/ModalForm";
import WithPermissions from "../permissions/WithPermissions";
import Typography from "../text/Typography";
import DeleteMailTemplateButton from "./DeleteMailTemplateButton";
import MailTemplateElementEdit from "./MailTemplateElementEdit";
import MailTemplateTypeBanner from "./MailTemplateTypeBanner";
import TemplatePlaceholderChip from "./TemplatePlaceholderChip";
import TemplatePreview from "./TemplatePreview";

export interface IUpdateMailTemplateButtonProps {
    isCreate?: boolean,
    existingTemplate?: IMailTemplate,
    templateType?: MailTemplate,
    openButton?: React.ReactElement<any, string | React.JSXElementConstructor<any>>,
    baseTemplate?: IGlobalMailTemplate,
    disabled?: boolean
}

export default function UpdateMailTemplateButton({existingTemplate, templateType, disabled, openButton, baseTemplate, isCreate}: IUpdateMailTemplateButtonProps) {

    const initialBaseTemplate = existingTemplate?.baseTemplate || baseTemplate || undefined;
    const canSelectBaseTemplate = !baseTemplate;

    const { getStringified, getDisplayValueForType } = useMailTemplateElements();
    const { reloadMailTemplates } = useMailTemplates();
    const { globalMailTemplates } = useGlobalMailTemplates();

    const callApi = useApi();

    const toggleButton = openButton || <Button text={isCreate ? "Neue Vorlage" : "Vorlage bearbeiten"} variant={isCreate ? "text" : "solid"} icon={isCreate ? "plus" : "pen"} />;

    if (!isCreate && !existingTemplate) return null;

    return (
        <WithPermissions permissions={["templates.mails.update"]}>

            <ModalForm
                disabled={disabled}
                initialValues={{
                    body: existingTemplate ? existingTemplate.body : (initialBaseTemplate?.body || []),
                    subject: existingTemplate ? existingTemplate.subject : (initialBaseTemplate?.subject || []),
                    templateType: templateType,
                    baseTemplate: initialBaseTemplate
                } as IMailTemplateDocument}
                enableReinitialize
                button={toggleButton}
                title={isCreate ? (baseTemplate ? `Neue Version von '${getDisplayValueForType(baseTemplate.templateType)}'`: "Neues Template") : "Template bearbeiten"}
                onSubmit={async (values) => {
                    const res = isCreate 
                    ? await callApi(mailTemplatesCreate(values)) 
                    : await callApi(mailTemplatesUpdate({...values, id: existingTemplate!._id}));

                    if (!res) return false;

                    await reloadMailTemplates();
                    return true;
                }}
                sidebar={
                    (formik, close) => (
                        <Flex fullHeight fullWidth justify="between">
                            <TemplatePreview content={formik.values} />
                            <Flex fullWidth gap={3}>
                                {
                                    initialBaseTemplate && (
                                        <Flex row wrap className="w-100">
                                            <Typography>Verfügbare Platzhalter aus Vorlage</Typography>
                                            {
                                                initialBaseTemplate.availablePlaceholders && !!initialBaseTemplate.availablePlaceholders.length 
                                                ? initialBaseTemplate.availablePlaceholders.map(p => <TemplatePlaceholderChip placeholder={p} />)
                                                : <em>Keine Platzhalter für diese Vorlage verfügbar</em>
                                            }
                                        </Flex>
                                    )
                                }
                                {
                                    (formik.dirty && !initialBaseTemplate)
                                    ? <Button color="muted" variant="text" text="Zurücksetzen" icon="arrow-clockwise" onClick={async () => formik.resetForm()} />
                                    : <div></div>
                                }
                                <Flex row wrap justify="between" className="w-100">
                                    {
                                        existingTemplate && <DeleteMailTemplateButton templateId={existingTemplate._id} afterDelete={() => close()} />
                                    }
                                    <Button type="submit" text="Speichern" icon="save" loading={formik.isSubmitting} />
                                </Flex>
                            </Flex>
                        </Flex>
                    )
                }
            >
                {
                    formik => (
                        <Flex className="w-100" gap={4} >
                            {
                                initialBaseTemplate && (
                                    <Flex fullWidth>
                                        <Typography>Ausgewählte Basisvorlage</Typography>
                                        <MailTemplateTypeBanner template={initialBaseTemplate.templateType} />
                                        <Typography wrap size={12} >Bitte achten Sie darauf, nur die verfügbaren Platzhalter zu verwenden. Wenn Sie einen Platzhalter in einem Textfeld ausgewählt haben und dieser nicht grün in der darunterliegenden Leiste aufleuchtet, ist dieser nicht verfügbar.</Typography>
                                    </Flex>
                                )
                            }
                            <Flex className="w-100">
                                <MailTemplateElementEdit name="subject" label="Betreff" availablePlaceholders={initialBaseTemplate ? initialBaseTemplate.availablePlaceholders : undefined} />
                            </Flex>
                            <Flex className="w-100">
                                <MailTemplateElementEdit name="body" label="Inhalt" availablePlaceholders={initialBaseTemplate ? initialBaseTemplate.availablePlaceholders : undefined} />
                            </Flex>
                        </Flex>
                    )
                }
            </ModalForm>
        </WithPermissions>
    )
}
