import React from "react";
import { AppColor } from "../../../app/AppStyles";
import { generateClassName, generateStyle } from "../../../hooks/useAttributes";
import useTiggySocket from "../../../hooks/useTiggySocket";
import useUserUtil from "../../../hooks/useUserUtil";
import { useTenant } from "../../../state/swr/tenant/useTenant";
import { useTicketClientContact } from "../../../state/swr/tickets/useTicketClientContact";
import { useUser } from "../../../state/swr/user/useUser";
import { useUsers } from "../../../state/swr/user/useUsers";
import { ITicket, ITicketActivity, ITicketMessage } from "../../../types/ApiTypes";
import { formatDateTiggiStyle } from "../../../util/formatter";
import { getTextColorFromBackground } from "../../../util/util";
import Card from "../../card/Card";
import Flex from "../../container/Flex";
import Divider from "../../sidebar/Divider";
import Typography from "../../text/Typography";
import UserRoleBanner from "../../user/RoleBanner";
import "./TicketChatMessage.css";
import TicketChatMessageAttachments from "./TicketChatMessageAttachments";
import TicketMessageHistory from "./TicketMessageHistory";
import TicketMessageInfoDialog from "./TicketMessageInfoDialog";
import TicketMessageReadIndicator from "./TicketMessageReadIndicator";
import TicketMessageText from "./TicketMessageText";
import { useTicketActivity } from "../../../state/swr/tickets/useTicketActivity";
import Pill from "../../pills/Pill";
import useTicketActivityUtil from "../../../hooks/useTicketActivityUtil";
import ClientTypeBanner from "../../clients/ClientTypeBanner";
import TicketLink from "../TicketLink";
import TeamBanner from "../../teams/TeamBanner";
import RoleBanner from "../../user/RoleBanner";

interface ITicketChatMessageProps {
    message: ITicketMessage,
    ticket: ITicket,
    colorMap: {[userId: string]: string}
}

export default function TicketChatMessage({ticket, message, colorMap}: ITicketChatMessageProps) {

    const { ticketClientContact } = useTicketClientContact(ticket);

    const { tenant } = useTenant();

    const {
        getName,
        getCurrentTenantRole
    } = useUserUtil();

    const {
        senderMailAddress,
    } = message;

    const {
        sendRead
    } = useTiggySocket();
    
    const {
        ticketActivityById
    } = useTicketActivity(ticket);
    
    const {
        getContent,
        getIcon    
    } = useTicketActivityUtil();

    const { user } = useUser();
    
    React.useEffect(() => {
        if (isOwn) return;
        if (message && message.isRead) return;
        sendRead(ticket._id, message._id);
        if (message.isRead) return;
    }, [message, ticket]);
    
    
    const isMentioned = () => {
        if (!message.mentions || !message.mentions.length) return false;
        if (!user) return false;
        return message.mentions.includes(user._id);
    }
    
    const isOwn = (typeof message.sentBy === "string" ? message.sentBy === user?._id : (message.sentBy?._id === user?._id || message.sentBy?.mailAddress === user?.mailAddress));
    const role = getCurrentTenantRole(typeof message.sentBy !== "string" ? message.sentBy : undefined);

    const className = generateClassName("ticket-chat-message w-100 d-flex flex-column position-relative", {
        value: isOwn,
        onTrue: "ticket-chat-message-own align-self-end",
        standard: "ticket-chat-message-other align-self-start"
    });

    const style = generateStyle({
        name: "color",
        value: isOwn ? getTextColorFromBackground("primary") : undefined
    });
    
    const cardColor = isMentioned() ? "primary" : (isOwn ? "primary" : undefined);

    if (!message || !message.content) return null;

    if (message.isSystemMessage) {

        const activity: ITicketActivity | undefined = ticketActivityById?.[message.ticketActivity];

        return (
            <Flex fullWidth justify="center" align="center">
                <Pill 
                    icon={getIcon(activity)}
                    color="#F3F3F3" 
                >
                    <Flex className="p-1">
                        <Typography>
                            {
                                message.content[0].text
                            }
                        </Typography>
                        {
                            activity && (
                                <Flex row wrap fullWidth>
                                    {
                                        activity.activityAffectsUser && <RoleBanner user={activity.activityAffectsUser} displayNameAsValue />
                                    }
                                    {
                                        activity.activityAffectsClient && <ClientTypeBanner client={activity.activityAffectsClient} showClientName />
                                    }
                                    {
                                        activity.mergedIntoTicket && <TicketLink ticket={activity.mergedIntoTicket} />
                                    }
                                    {
                                        activity.activityAffectsTeam && <TeamBanner team={activity.activityAffectsTeam} />
                                    }
                                </Flex>
                            )
                        }
                    </Flex>
                </Pill>
            </Flex>
        )
    }

    return (
        <Card 
            noPadding
            color={cardColor}
            wrapperClass={className}
            header={
                <Flex className="w-100">
                    <Flex row justify={isOwn ? "end" : "between"} align="start" className="w-100" wrap>
                        {
                            !isOwn && (
                                <Flex gap={0}>
                                    {
                                            <Flex row>
                                                {
                                                    message.sentBy && typeof message.sentBy !== "string" && <Typography bold color="primary" size={14}>{getName(message.sentBy)}</Typography> 
                                                }
                                                {
                                                    !!(role && role.isClient) && <Typography color="primary">| {ticketClientContact && ticketClientContact.client ? ticketClientContact.client.name : "Kein Mandant"}</Typography> 
                                                }
                                            </Flex>
                                    }
                                    <Typography size={12} basedOnThisBackground={isOwn ? "primary" : undefined}>
                                        {
                                            (role && !role.isClient && tenant && tenant.name) 
                                            ? `@${tenant.name}`
                                            : senderMailAddress
                                        }
                                    </Typography>
                                </Flex>
                            )
                        }
                        <Flex row wrap className="gap-ms-3 gap-1">
                            <TicketMessageInfoDialog ticketMessage={message} ticket={ticket} />
                            {
                                isOwn && <TicketMessageReadIndicator message={message} />
                            }
                            <TicketMessageHistory message={message} />
                            <Typography className="ms-auto" basedOnThisBackground={isOwn ? "primary" : undefined} size={14}>{formatDateTiggiStyle(message.createdAt)}</Typography>
                        </Flex>
                    </Flex>
                </Flex>
            }
        >
            <Flex className="ps-3 pb-2 pt-3 pe-3 w-100" style={style}>
                <TicketMessageText message={message} canEdit={isOwn} />
                <TicketChatMessageAttachments message={message} />
                <MentionedUsers message={message} cardColor={cardColor} />
            </Flex>
        </Card>
    )
}

function MentionedUsers({message, cardColor}: {message: ITicketMessage, cardColor?: AppColor}) {
    const { users, loadingUsers } = useUsers();

    if (loadingUsers) return null;
    if (!users || !users.length) return null;
    if (!message.mentions || !message.mentions.length) return null;

    const mentionedUsers = users.filter(u => message.mentions.includes(u._id));

    if (!mentionedUsers.length) return null;

    return (
        <Flex fullWidth>
            <Divider height={1} opacity={0.5}/>
            <Typography basedOnThisBackground={cardColor} >Erwähnt:</Typography>
            <Flex row fullWidth>
                {mentionedUsers.map(m => <UserRoleBanner key={m._id} user={m} displayNameAsValue />)}
            </Flex>
        </Flex>
    )
}