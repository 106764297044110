import { useTeams } from "../state/swr/teams/useTeams";
import { useUser } from "../state/swr/user/useUser";
import { ITeam, IUser } from "../types/ApiTypes";

export default function useTeamsUtil() {
    const { teams } = useTeams();
    const { user: currentUser } = useUser();

    const getAvailableTeamsForUser = (user: IUser): ITeam[] => {
        if (!teams || !teams.length) return [];
        if (!user) return [];

        return teams.filter(t => t.members && !!t.members.length && t.members.find(u => u._id === user?._id));
    }

    const isUserTeamMember = (u: IUser) => {
        if (!currentUser) return false;
        if (!teams || !teams.length) return false;
        const userTeams = getAvailableTeamsForUser(currentUser);
        if (!userTeams) return false;
        return userTeams.some(t => t.members && !!t.members.length && t.members.find(m => m._id === u._id));
    }

    const getCurrentTenantTeams = (): ITeam[] => {
        if (!teams || !teams.length) return [];
        return teams;
    }

    return {
        getCurrentTenantTeams,
        isUserTeamMember,
        getAvailableTeamsForUser
    }
}