import React from "react";
import { UserRoleColor } from "../../app/AppStyles";
import { useAppSelector } from "../../hooks/reduxHooks";
import useUserUtil from "../../hooks/useUserUtil";
import { IRole, IRoleDocument, ITenant, IUser, UserRole } from "../../types/ApiTypes";
import IElementProps from "../../types/ElementProps";
import Pill from "../pills/Pill";
import Banner from "../pills/Banner";
import Typography from "../text/Typography";
import { generateClassName } from "../../hooks/useAttributes";
import Flex from "../container/Flex";
import { getTextColorFromBackground } from "../../util/util";
import "./RoleBanner.css";

interface IRoleBannerProps extends IElementProps {
    user?: IUser | undefined | null,
    role?: IRoleDocument | null | undefined,
    showMail?: boolean,
    showOnEmptyText?: string,
    tenant?: ITenant | null,
    displayNameAsValue?: boolean,
    onClick?: () => Promise<any>,
    inactive?: boolean,
    displayTenantNameAsValue?: boolean
}

export default function RoleBanner({user, role, inactive, onClick, tenant, showOnEmptyText, displayNameAsValue, displayTenantNameAsValue = false, showMail, className, children}: IRoleBannerProps) {

    const {
        getName,
        getAuthority
    } = useUserUtil();

    const rgbToHex = (r: number, g: number, b: number)  => {
        return "#" + (1 << 24 | r << 16 | g << 8 | b).toString(16).slice(1);
    }

    const authority = getAuthority(user, tenant);

    const realRole = role || authority?.userAuthority?.role;

    const getColorBasedOnRole = (): string => {
        if (inactive) return "#E8E8E8";
        if (!realRole) return "#F0F0F0";

        if (realRole.isClient) return "#F0BB92"

        const accessLevel = realRole.accessLevel;
        
        if (accessLevel === undefined || accessLevel === null) return "#FFFFFF";
        if (accessLevel > 100) return "#000000";

        const maxR = 200;
        const maxG = 180;
        const maxB = 220;

        const r = accessLevel < 50 ? 0 : (accessLevel - 50) * 5.1;
        const g = accessLevel < 50 ? 255 - accessLevel * 5.1 : 0;
        const b = accessLevel < 50 ? accessLevel * 5.2 : 255 - (accessLevel - 50) * 5.2;

        const realR = r < 0 ? 0 : r > maxR ? maxR : r;
        const realG = g < 0 ? 0 : g > maxG ? maxG : g;
        const realB = b < 0 ? 0 : b > maxB ? maxB : b;

        return rgbToHex(realR, realG, realB);
    }

    const realClassName = generateClassName(className, {
        value: inactive,
        onTrue: "role-banner-inactive"
    });

    if (!user && !realRole) {
        if (showOnEmptyText) return <Banner text={showOnEmptyText} color="bright" />
        return null;
    }

    const showText = !showMail;
    const name = getName(user);

    const realText = (
        displayTenantNameAsValue && tenant
        ? tenant.name
        : (
            showMail
            ? undefined
            : displayNameAsValue && name
        )
    )

    const color = getColorBasedOnRole();

    return (
        <Pill 
            color={color} 
            icon={"person"} 
            className={realClassName} 
            text={showText ? (realText || realRole?.displayName || "Keine Rolle") : undefined} 
            onClick={onClick} 
        >
            {
                showMail && user && (
                    <Flex gap={0} fullWidth>
                        <Typography color={getTextColorFromBackground(color)} size={12}>{name}</Typography>
                        <Typography color={getTextColorFromBackground(color)} size={9}>{user.mailAddress}</Typography>
                    </Flex>
                )
            }
        </Pill>
    )
}