import { ArrayHelpers, FieldArray, useFormikContext } from "formik";
import React from "react";
import { IMailTemplateContent, IMailTemplateElement, MailTemplateElementType, TemplatePlaceholder } from "../../types/ApiTypes";
import Flex from "../container/Flex";
import Typography from "../text/Typography";
import Button from "../buttons/Button";
import Card from "../card/Card";
import FormikField from "../formik/FormikField";
import MailTemplateEditElementPlaceholders from "./MailTemplateEditElementPlaceholders";
import Icon from "../icons/Icon";
import Select from "../comboBox/Select";

export interface IMailTemplateElementEditProps {
    name: "subject" | "body",
    label: string,
    availablePlaceholders?: TemplatePlaceholder[],
    onPlaceholderSelect?: (placeholder: TemplatePlaceholder) => void,
    onPlaceholderRemove?: (placeholder?: TemplatePlaceholder) => void
}

export const PlaceholderMatch = /%{\[([^\]]*)\]}%/g;

export default function MailTemplateElementEdit({name, label, availablePlaceholders, onPlaceholderRemove, onPlaceholderSelect}: IMailTemplateElementEditProps) {

    const {
        values,
        setFieldValue
    } = useFormikContext<IMailTemplateContent>();

    return (
        <FieldArray name={name}>
            {
                arrayHelpers => (
                    <Flex className="w-100">
                        <Flex row className="w-100" justify="between">
                            <Typography size={20} bold color="primary">{label}</Typography>
                            <Button 
                                onClick={async () => arrayHelpers.push({ 
                                    content: "", 
                                    fallbackOnPlaceholderUnavailable: "", 
                                    placeholder: undefined,
                                    type: MailTemplateElementType.Text
                                } as IMailTemplateElement)} 
                                text="Neues Element" 
                                icon="plus" 
                            />
                        </Flex>
                        {
                            values[name] && values[name].length 
                            ? (
                                values[name].map((element: IMailTemplateElement, index: number) => {
                                    const saveCurrentElementPlaceholder = (placeholder: TemplatePlaceholder) => {
                                        setFieldValue(`${name}.${index}.placeholder`, placeholder);
                                        if (onPlaceholderSelect) onPlaceholderSelect(placeholder);
                                    }

                                    const removeCurrentElementPlaceholder = () => {
                                        setFieldValue(`${name}.${index}.placeholder`, undefined);
                                        if (onPlaceholderRemove) onPlaceholderRemove();
                                    }

                                    const moveItemUp = () => {
                                        if (index === 0) return;
                                        arrayHelpers.swap(index, index - 1);
                                    }

                                    const moveItemDown = () => {
                                        if (!values[name] || !values[name].length) return;
                                        if (index === values[name].length - 1) return;
                                        arrayHelpers.swap(index, index + 1);
                                    }

                                    const getValidationError = (): string | null => {
                                        switch (element.type) {
                                            case MailTemplateElementType.Text: 
                                                if (PlaceholderMatch.test(element.content)) return "Textblock darf keinen Platzhalter beinhalten.";
                                                return null;
                                            case MailTemplateElementType.Placeholder: 
                                                if (!element.content) return "Bitte wählen Sie einen Platzhalter aus!";
                                                return null;
                                        }

                                        return null;
                                    }

                                    const validationText = getValidationError();

                                    const canMoveUp = index !== 0;
                                    const canMoveDown = values[name] && !!values[name].length && index !== values[name].length - 1;

                                    return (
                                        <Flex fullWidth row key={`template-element-${index}`}>
                                            <Flex fullHeight>
                                                {
                                                    canMoveUp && <Icon icon="chevron-up" onClick={moveItemUp} color="primary" />
                                                }
                                                {
                                                    canMoveDown && <Icon icon="chevron-down" onClick={moveItemDown} color="primary" />
                                                }
                                            </Flex>
                                            <Card 
                                                key={index} 
                                                color={validationText ? "error" : undefined}
                                                header={
                                                    <Flex row justify="between">
                                                        <Flex row gap={1}>
                                                            {
                                                                !!validationText 
                                                                ? <Typography bold basedOnThisBackground="error">{validationText}</Typography>
                                                                : (
                                                                    <Flex row>
                                                                        <Select
                                                                            values={[
                                                                                { data: MailTemplateElementType.Text, label: "Textblock" },
                                                                                { data: MailTemplateElementType.Placeholder, label: "Platzhalter" },
                                                                                { data: MailTemplateElementType.LineBreak, label: "Zeilenumbruch" },
                                                                                { data: MailTemplateElementType.TicketAnswerPrompt, label: "Ticketantwort-Trennzeile" }
                                                                            ]}
                                                                            value={element.type}
                                                                            onChange={newVal => setFieldValue(`${name}.${index}.type`, newVal)}
                                                                        />
                                                                        <Icon icon="pen" color="primary" tooltip="Links können Sie den Typ des Elements verändern" />
                                                                    </Flex>
                                                                )
                                                            }
                                                        </Flex>
                                                        <Button 
                                                            variant="text" 
                                                            text="Entfernen" 
                                                            size="small" 
                                                            iconSize={12} 
                                                            icon="trash" 
                                                            color={!validationText ? "error" : "bright"} 
                                                            onClick={async () => arrayHelpers.remove(index)} 
                                                        />
                                                    </Flex>
                                                }
                                            >
                                                {
                                                    element.type === MailTemplateElementType.Text && (
                                                        <Flex className="w-100" gap={3} justify="between">
                                                            <FormikField 
                                                                className="w-100"
                                                                label="Inhalt"
                                                                name={`${name}.${index}.content`}
                                                            />
                                                        </Flex>
                                                    )
                                                }
                                                {
                                                    element.type === MailTemplateElementType.Placeholder && (
                                                        <Flex fullWidth>
                                                            <MailTemplateEditElementPlaceholders
                                                                saveText={newVal => setFieldValue(`${name}.${index}.content`, newVal)}
                                                                availablePlaceholders={availablePlaceholders}
                                                                currentText={element.content}
                                                                selectedPlaceholder={element.placeholder}
                                                                onAdd={saveCurrentElementPlaceholder}
                                                                onRemove={removeCurrentElementPlaceholder}
                                                            />
                                                            <FormikField
                                                                className="w-100"
                                                                label="Ersatztext bei nicht verfügbarem Platzhalter"
                                                                placeholder="Ersatztext, falls der Platzhalter im Kontext der Mail nicht ersetzt werden kann..."
                                                                name={`${name}.${index}.fallbackOnPlaceholderUnavailable`}
                                                            />
                                                        </Flex>
                                                    )
                                                }
                                            </Card>
                                        </Flex>
                                    )
                                })
                            )
                            : <em>Keine Elemente vorhanden</em>
                        }
                    </Flex>
                )
            }
        </FieldArray>
    )
}

