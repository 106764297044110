import React from "react";
import { generateClassName, generateStyle, generateStyleWithBase } from "../../hooks/useAttributes";
import IElementProps from "../../types/ElementProps";
import { getTextColorFromBackground } from "../../util/util";
import Icon from "../icons/Icon";
import Typography from "../text/Typography";
import "./Pill.css";

interface IPillProps extends IElementProps {
    color?: string,
    text?: string,
    hoverText?: string,
    hoverColor?: string,
    hoverIcon?: string,
    icon?: string,
    loading?: boolean,
    loadingText?: string,
    disabled?: boolean,
    textColor?: string,
    rounded?: boolean,
    childPosition?: "start" | "end"
}

export default function Pill({
    children, 
    icon, 
    hoverIcon,
    loading,
    loadingText,
    disabled,
    hoverColor,
    hoverText,
    childPosition = "end", 
    style, 
    rounded = false, 
    textColor, 
    onClick, 
    text, 
    color = "#FFFFFF", 
    className, 
    onMouseEnter, 
    onMouseLeave, 
    onMouseOver
}: IPillProps) {

    const [hover, setHover] = React.useState<boolean>(false);
    const [isLoading, setIsLoading] = React.useState<boolean>(!!loading);

    React.useEffect(() => {
        if (loading === undefined) return;
        setIsLoading(loading);
    }, [loading]);

    const clickHandler = async () => {
        if (!onClick) return;

        setIsLoading(true);

        try {
            await onClick();
        }
        catch { }
        
        setIsLoading(false);
    }

    const isDisabled = isLoading || disabled;

    const getBackgroundColor = () => {
        if (isDisabled) return "#F3F3F3";
        if (hover) return hoverColor ?? color;
        return color;
    }

    const background = getBackgroundColor();
    const tc = isDisabled ? "#A3A3A3" : (textColor ?? getTextColorFromBackground(color, "#FFFFFF"));

    const content = (
        isLoading 
        ? <Typography color={tc}>{loadingText ?? "Lädt..."}</Typography>
        : (
            (hover && hoverText)
            ? <Typography color={tc}>{hoverText}</Typography>
            : ( children ?? <Typography color={tc}>{text}</Typography> )
        )
    );
    
    return (
        <div 
            onMouseEnter={e => {
                setHover(true);
                onMouseEnter?.(e);
            }} 
            onMouseLeave={e => {
                setHover(false);
                onMouseLeave?.(e);
            }}
            onMouseOver={e => {
                setHover(true);
                onMouseOver?.(e);
            }}
            className={generateClassName(className, "pill", { value: !!onClick, onTrue: "pill-clickable"}, { value: rounded, onTrue: "pill-rounded" })} 
            onClick={clickHandler}
            >
            <div className="pill-inner w-100 h-100 m-0 p-0 position-relative">
                <div className="pill-bg position-absolute top-0 start-0 w-100 h-100" style={generateStyle({ name: "backgroundColor", value: background, isVar: !!background && !background.includes("#") })} />
                <div 
                    className={generateClassName("pill-content position-relative", className, "d-flex w-100 align-items-center justify-content-center gap-2 flex-row")} 
                    style={generateStyleWithBase(style, [{ name: "color", value: tc, important: true }])}
                >
                    {
                        childPosition === "start" && content
                    }
                    {
                        (loading || icon || hoverIcon) && <Icon icon={hover && hoverIcon ? hoverIcon : icon} loading={isLoading} />
                    }
                    {
                        childPosition === "end" && content
                    }
                </div>
            </div>
        </div>
    )
}