
export interface IClassCondition {
    base?: string,
    value?: any,
    standard?: any,
    onTrue?: any
}

export interface IStyleCondition {
    name: keyof React.CSSProperties,
    applyCondition?: boolean,
    isVar?: boolean,
    important?: boolean,
    value?: any,
    standard?: any,
    unit?: string
}

const processClassCondition = ({base = "", value = null, standard = "", onTrue = ""}: IClassCondition): string  => `${base}${!!value ? !!onTrue ? onTrue : value : standard}`;

type ClassNameArg = string | IClassCondition | undefined;

export const generateClassName = (...args: Array<ClassNameArg>): string | undefined => {
    if (!args || !args.length) return undefined;

    const result = args.reduce((prev: string, current: ClassNameArg) => {
        if (!current) return prev;

        const previousValueExists = prev.length > 0;

        const isString = typeof current === "string";

        if (isString && current.length === 0) return prev;

        if (isString) return previousValueExists ? `${prev} ${current}` : current;
        
        const conditionResult = processClassCondition(current);

        if (!conditionResult) return prev;
        return previousValueExists ? `${prev} ${conditionResult}` : conditionResult;
    }, '');

    return result || undefined;
}

const processStyleCondition = ({name, value = null, standard = "", isVar = false, important = false, unit = "", applyCondition = true}: IStyleCondition): IStyleCondition | null => {
    if (!name) return null;
    if (!applyCondition) return null;
    
    const actualValue = (value !== undefined && value !== null) ? value : standard;

    if (actualValue === undefined || actualValue === null) return null;

    const styleValue = isVar ? `var(--${actualValue})` : actualValue;

    const result = {
        name: name,
        value: styleValue
    };

    const valWithUnit = !!unit ? styleValue + unit : styleValue;

    const resultValue = important ? `${valWithUnit}` : valWithUnit;

    result.value = resultValue;

    return result;
}

export const generateStyle = (...conditions: IStyleCondition[]) => generateStyleWithBase(undefined, conditions);

export const generateStyleWithBase = (base: Object = {}, conditions?: IStyleCondition[]) => {
    if (!base) base = {};

    if (!conditions || !conditions.length) return base;

    conditions.forEach((v: IStyleCondition) => {
        const processedStyle = processStyleCondition(v);
        if (!processedStyle) return;
        base[processedStyle.name as keyof Object] = processedStyle.value;
    });

    return base;
}
