import { ApiRoutes } from "../../../api/ApiRoutes";
import { ITicketFilter } from "../../../hooks/useFilteredTickets";
import useFilterQuery from "../../../hooks/useFilterQuery";
import useTiggiQuery from "../../../hooks/useTiggiQuery";
import { IHydratedTicket } from "../../../types/ticket.types";

export const useTicketsWithFilter = (filter: ITicketFilter) => {

    const {
        getUrlWithFilter
    } = useFilterQuery();

    const { data, isLoading, mutate } = useTiggiQuery<Array<IHydratedTicket>>(getUrlWithFilter(ApiRoutes.Tickets.Filtered, filter));

    return {
        tickets: data,
        loadingTickets: isLoading,
        validatingTickets: isLoading,
        reloadTickets: mutate
    }
}