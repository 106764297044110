import React, { MouseEventHandler } from "react";
import { AppColor } from "../../app/AppStyles";
import { generateClassName, generateStyle } from "../../hooks/useAttributes";
import IElementProps from "../../types/ElementProps";
import "./Icon.css";
import { getTextColorFromBackground } from "../../util/util";
import LoadingSpinner from "../loader/LoadingSpinner";
import Typography from "../text/Typography";
import Tooltip from "../tooltip/Tooltip";
import Flex from "../container/Flex";
import { boolean } from "yup";

interface IIconProps extends IElementProps {
    icon?: string,
    labelClass?: string,
    labelColor?: AppColor,
    label?: string,
    color?: AppColor,
    tooltip?: string,
    hexColor?: string,
    size?: number,
    loading?: boolean,
    invertThisColor?: string,
    wrapperClass?: string
}

export interface IIconTooltipOrigin {
    top: number,
    left: number,
}

export default function Icon({icon, labelColor, wrapperClass, size = 16, onClick, tooltip, label, color, invertThisColor, hexColor, style, className, labelClass, loading}: IIconProps) {
    
    const hoverTimeout = React.useRef<any>();

    const iconStyle = generateStyle({
            name: "fontSize",
            unit: "px",
            value: size
        }, {
            name: "lineHeight",
            unit: "px",
            value: size
        }, {
            value: invertThisColor ? getTextColorFromBackground(invertThisColor) : (hexColor || color),
            isVar: !invertThisColor && !hexColor,
            name: "color"
        }
    );

    const finalIconClass = generateClassName("icon", className, { base: "bi-", value: icon }, { value: !!(onClick), onTrue: "icon-clickable" });

    const clickHandler = async () => {
        if (!onClick) return;
        clearTimeout(hoverTimeout.current);
        try {
            await onClick();
        }
        catch { }
    }

    if (loading) return <LoadingSpinner size={size} className={wrapperClass} color={color} hexColor={hexColor} />;
    if (!icon) return null;

    const labelClassName = generateClassName(labelClass, "icon-label text-nowrap");

    return (
        <Tooltip tooltip={tooltip} className={wrapperClass}>
            <Flex row onClick={clickHandler} gap={1}>
                {
                    loading 
                    ? <LoadingSpinner size={size} />
                    : <i className={finalIconClass} style={style ? {...iconStyle, ...style} : iconStyle}></i>
                }
                {
                    label && <Typography color={labelColor || color} className={labelClassName} size={12}>{label}</Typography>
                }
            </Flex>
        </Tooltip>
    )
}