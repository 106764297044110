import { FieldArray, FormikProps, useFormik, useFormikContext } from "formik";
import React from "react";
import { Status } from "../../config/Status";
import { useAppDispatch, useAppSelector } from "../../hooks/reduxHooks"
import { IClient, IRole, ITenant, ITenantAuthority, IUser } from "../../types/ApiTypes";
import LabelButton from "../buttons/LabelButton";
import SearchableComboBox from "../comboBox/SearchableComboBox";
import CheckBox from "../formik/CheckBox";
import Icon from "../icons/Icon";
import LoadingSpinner from "../loader/LoadingSpinner";
import RoleBanner from "./RoleBanner";
import "./UserAuthorityFieldArray.css";
import Button from "../buttons/Button";
import { useTenants } from "../../state/swr/tenant/useTenants";
import { useRoles } from "../../state/swr/roles/useRoles";
import Flex from "../container/Flex";
import Typography from "../text/Typography";
import { useUser } from "../../state/swr/user/useUser";
import { useTenant } from "../../state/swr/tenant/useTenant";
import { useCurrentTenantAuthority } from "../../state/swr/user/useCurrentTenantAuthority";
import Card from "../card/Card";
import UserSelect from "./UserSelect";

export interface IUserAuthorityFieldArrayProps {
    isClientContact: boolean,
    isCreate: boolean
}

export default function UserAuthorityFieldArray({isClientContact, isCreate}: IUserAuthorityFieldArrayProps) {

    const { roles, loadingRoles } = useRoles();

    const {
        tenant
    } = useTenant();

    const {
        user
    } = useUser();

    const {
        authority
    } = useCurrentTenantAuthority();

    const {
        values,
        setFieldValue
    } = useFormikContext<IUser>();

    const { tenants, loadingTenants } = useTenants();

    if (!user) return null;
    if (!roles) return null;

    const getAvailableRoles = () => {
        if (!roles) return [];
        return roles.filter(r => r.isClient === isClientContact);
    }

    const canBeAssignedToMoreTenants = user.isSuperAdmin && !!values.authority && !!tenants && values.authority.length < tenants.length;

    const isSuperAdmin = user.isSuperAdmin;
    const isTenantAdmin = !!authority?.isTenantAdmin;
    const availableRoles = getAvailableRoles();

    return (
        <FieldArray name="authority">
            {
                (arrayHelpers) => {
                    return (
                        <div className="user-authority-field-array-container d-flex flex-column align-items-start w-100 gap-2">
                            <Flex row justify="between" fullWidth>
                                <Typography color="primary" bold>{ (isSuperAdmin && !isCreate) ? "Zugriffsverwaltung" : "Rolle" }</Typography>
                                {
                                    isSuperAdmin && !isCreate && (
                                        <Button  
                                            loading={loadingTenants}
                                            text={canBeAssignedToMoreTenants ? "Weiterer Tenant" : "Allen Tenants zugewiesen"} 
                                            icon="plus" 
                                            disabled={!canBeAssignedToMoreTenants} 
                                            onClick={async () => arrayHelpers.push({
                                                tenant: tenants && !!tenants.length && tenants[0],
                                                role: roles && !!roles.length && roles[0]
                                            } as ITenantAuthority)} 
                                        />
                                    )
                                }
                            </Flex>
                            <div className="user-authority-field-array d-flex flex-column gap-2 w-100">
                                {
                                    values.authority && !!values.authority.length 
                                    ? values.authority.map((a, index) => {
                                        if (!a.tenant) return null;
                                        if (!isSuperAdmin && a.tenant._id !== tenant._id) return null;

                                        return (
                                            <Card
                                                key={a._id || `user-authority-field-array-item-${index}`}
                                                color="bright"
                                                header={(
                                                    <Flex fullWidth row justify="between" align="start">
                                                        <Flex gap={0}>
                                                            <Typography bold color="primary">{a.tenant.name}</Typography>
                                                            { (isTenantAdmin || isSuperAdmin) && <CheckBox labelClass="text-nowrap" label="Tenant-Admin" name={`authority[${index}].isTenantAdmin`} /> }
                                                        </Flex>
                                                        { isSuperAdmin && !isCreate && <Button icon="trash" text="Zugriff entfernen" color="error" variant="text" />}
                                                    </Flex>
                                                )}
                                            >
                                                <Flex fullWidth row>
                                                    <SearchableComboBox 
                                                        label="Rolle"
                                                        loading={loadingRoles}
                                                        renderItem={r => <RoleBanner role={r} />}
                                                        renderValue={r => <RoleBanner role={r} />}
                                                        placeholder="Rolle..."
                                                        className="w-100"
                                                        values={availableRoles || roles || []} 
                                                        onItemClick={(r) => setFieldValue(`authority[${index}].role`, r)}
                                                        value={a.role} 
                                                        itemToId={(role: IRole) => role._id} 
                                                        itemToString={(role: IRole) => role.displayName} 
                                                    />
                                                    <UserSelect
                                                        label="Vorgesetzter"
                                                        onChange={(u) => setFieldValue(`authority[${index}].supervisor`, u)}
                                                        value={a.supervisor}
                                                        displayed="employees"
                                                    />
                                                </Flex>
                                            </Card>
                                        );
                                    })
                                    : <em>Kein Zugriff zugewiesen.</em>
                                }
                            </div>
                        </div>
                    )
                }
            }
        </FieldArray>
    )
}