import React from "react";
import useFilteredTickets, { TicketFilter, TicketView } from "../../../hooks/useFilteredTickets";
import useTicketUtil from "../../../hooks/useTicketUtil";
import { Locale } from "../../../locale/Locale";
import { useTicketsWithFilter } from "../../../state/swr/tickets/useTicketsWithFilter";
import { useCurrentTenantRole } from "../../../state/swr/user/useCurrentTenantRole";
import { useUser } from "../../../state/swr/user/useUser";
import { ITicket, IUser, TicketState } from "../../../types/ApiTypes";
import { IHydratedTicket } from "../../../types/ticket.types";
import Button from "../../buttons/Button";
import Flex from "../../container/Flex";
import InfiniteScroll from "../../infiniteScroll/InfiniteScroll";
import PageHeader from "../../label/PageHeader";
import LoadingSpinner from "../../loader/LoadingSpinner";
import Divider from "../../sidebar/Divider";
import Table from "../../tables/Table";
import TabSwitcher from "../../tabswitcher/TabSwitcher";
import TeamSelect from "../../teams/TeamSelect";
import CreateTicketModal from "../CreateTicketModal";
import SortTicketsButton from "../filter/SortTicketsButton";
import TicketClientFilter from "../filter/TicketClientFilter";
import TicketSearchBox from "../filter/TicketSearchBox";
import MergeTicketButton from "../status/MergeTicketButton";
import UpdateTicketStatusButton from "../status/UpdateTicketStatusButton";
import "./TicketOverview.css";
import TicketOverviewItem from "./TicketOverviewItem";

export interface ISortedTicketElement {
    team: {
        _id: string,
        name: string,
        members: IUser[]
    },
    tickets: ITicket[]
}

export default function TicketOverview() {
    const [selectedTickets, setSelectedTickets] = React.useState<Array<ITicket>>();
    const [activeTab, setActiveTab] = React.useState<TicketFilter>();

    const {
        getDisplayId
    } = useTicketUtil();

    const {
        view,
        updateFilter,
        team,
        getViewTabs,
        getStatisticsTabs,
        getNotAssignedTypeTabs,
        getFilterTabs,
        getFilterForRoute
    } = useFilteredTickets();

    const { role } = useCurrentTenantRole();
    const { user } = useUser();

    const { loadingTickets, reloadTickets, tickets, validatingTickets } = useTicketsWithFilter(getFilterForRoute());

    const updateSelectedTickets = (ticket: ITicket) => {
        if (!selectedTickets || !selectedTickets.length) {
            setSelectedTickets([ticket]);
            return;
        }

        const existingIndex = selectedTickets.findIndex(t => t._id === ticket._id);

        if (existingIndex >= 0) {
            const newSelectedTickets = [...selectedTickets];
            newSelectedTickets.splice(existingIndex, 1);
            setSelectedTickets(newSelectedTickets);
            return;
        }

        setSelectedTickets([...selectedTickets, ticket]);
    }

    const isClient = !(role && !role.isClient) && !(!!user && user.isSuperAdmin);
    const hasAnySelection = !!selectedTickets && !!selectedTickets.length;
    
    const isTicketSelected = (s: ITicket) => !!selectedTickets && !!selectedTickets.find(t => t._id === s._id);

    return (
        <Flex className="w-100 h-100" gap={4} style={{ overflowY: "auto" }}>
            <Flex className="w-100" gap={1}>
                <Flex row gap={3} className="w-100" justify="between" wrap>
                    <Flex row gap={3} >
                        <PageHeader text={Locale.titles.tickets} />
                        <CreateTicketModal />
                    </Flex>
                    {
                        hasAnySelection && !isClient
                        ? (
                            <Flex row wrap gap={4}>
                                <Button variant="text" icon="x" iconSize={20} onClick={async () => setSelectedTickets([])}>Aufheben</Button>
                                <Flex row wrap justify="end">
                                    <MergeTicketButton selectedTickets={selectedTickets} variant="solid" afterAction={() => setSelectedTickets(undefined)} />
                                    {
                                        activeTab === TicketFilter.Closed 
                                        ? <UpdateTicketStatusButton tickets={selectedTickets} variant="solid" afterAction={() => setSelectedTickets(undefined)} />
                                        : (
                                            <>
                                                <UpdateTicketStatusButton tickets={selectedTickets} variant="solid" afterAction={() => setSelectedTickets(undefined)} state={TicketState.Solved} />
                                                <UpdateTicketStatusButton tickets={selectedTickets} variant="solid" afterAction={() => setSelectedTickets(undefined)} state={TicketState.Resubmission} />
                                             </>
                                        )
                                    }
                                </Flex>
                                <UpdateTicketStatusButton tickets={selectedTickets} variant="solid" afterAction={() => setSelectedTickets(undefined)} isDelete />
                            </Flex>
                        )
                        : (
                            
                                <Flex row gap={3} className="ms-auto">
                                    <TicketClientFilter />
                                    <Divider height={24} color="muted" opacity={0.25} width={2}/>
                                    <SortTicketsButton />
                                    <TabSwitcher 
                                        variant="icons" 
                                        tabQueryParamKey="view" 
                                        tabs={getViewTabs()}
                                    />
                                </Flex>
                        )
                    }
                </Flex>
                <Flex row justify="between" className="w-100" wrap>
                    <TabSwitcher tabs={getFilterTabs()} primaryPriority={1} displayedPriority={1} tabQueryParamKey="show" saveActiveTab={s => setActiveTab(s as TicketFilter)} />
                    <Flex row gap={4}>
                        <TabSwitcher variant="muted" primaryPriority={1} tabs={getFilterTabs()} displayedPriority={2} tabQueryParamKey="show" saveActiveTab={s => setActiveTab(s as TicketFilter)} />
                        <TicketSearchBox />
                    </Flex>
                </Flex>
                {
                    !isClient 
                    && (

                        (activeTab === TicketFilter.ForMe || activeTab === TicketFilter.All || activeTab === TicketFilter.MyTeam)
                        ? (
                            <TabSwitcher
                            tabQueryParamKey="subcategory"
                            tabs={getStatisticsTabs()}
                            size="tiny" 
                            />
                        )
                        : (
                            activeTab === TicketFilter.NotAssigned
                            ? (
                                <TabSwitcher 
                                    tabQueryParamKey="notAssignedType"
                                    tabs={getNotAssignedTypeTabs()}
                                    size="tiny"
                                />
                            )
                            : <div></div>
                        )
                    )
                }
                {
                    activeTab === TicketFilter.MyTeam 
                    && (
                        <Flex row justify="between" className="w-100">
                            <TeamSelect onTeamClick={t => updateFilter({team: t ? t._id : ""})} selectedTeam={team} />
                        </Flex>
                    )
                }
            </Flex>
            {
                (validatingTickets || loadingTickets) && <LoadingSpinner size={12} color="primary" text={validatingTickets ? "Tickets werden aktualisiert" : "Tickets werden geladen..."}/>
            }
            {
                tickets && !!tickets.length
                ? (
                    view === TicketView.Grid
                    ? (
                        <div className="w-100 ticket-overview-item-container ticket-grid">
                            <InfiniteScroll itemsPerPage={5}>
                                {
                                    tickets && !!tickets.length
                                    ? tickets.map(s => <TicketOverviewItem key={s.ticket._id} hasAnySelection={hasAnySelection} isSelected={isTicketSelected(s.ticket)} initialTicket={s} toggleTicketSelected={updateSelectedTickets} />)
                                    : <span className="fw-bold">Keine Tickets</span>
                                }
                            </InfiniteScroll>
                        </div>
                    )
                    : (
                        <Table
                            headers={[
                                { label: "" },
                                {
                                    label: "Nummer",
                                    valueKey: "friendlyId",
                                    filterItem: (t: IHydratedTicket, f: string) => getDisplayId(t.ticket).toLowerCase().includes(f.toLowerCase())
                                },
                                {
                                    label: "Betreff",
                                    valueKey: "subject"
                                },
                                { label: "Art" },
                                { label: "Letzte Nachricht" },
                                { label: "Team", hidden: isClient },
                                { label: "Kontakt" },
                                { label: "Nachrichten" },
                                { label: "Status" },
                                { label: "Letztes Update" },
                                { label: "" }
                            ]}
                            items={tickets}
                            renderItem={(t: IHydratedTicket) => (
                                <TicketOverviewItem 
                                    key={t.ticket._id} 
                                    hasAnySelection={hasAnySelection} 
                                    isSelected={isTicketSelected(t.ticket)} 
                                    initialTicket={t}
                                    toggleTicketSelected={updateSelectedTickets} 
                                    renderAsTableRow
                                />
                            )}
                        />
                    )
                )
                : <span className="fw-bold">Keine Tickets</span>
            }
        </Flex>
    )
}