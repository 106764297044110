import React from "react";
import useUserUtil from "../../hooks/useUserUtil";
import Flex from "../container/Flex";
import Typography from "../text/Typography";
import TicketStatisticPie from "./TicketStatisticPie";
import { generatePleasantBlueHex, getColorOrShade } from "../../util/color";
import "./TicketStatsPieGrid.css";
import { AppColor } from "../../app/AppStyles";
import { IUserStatisticsCounts } from "../../types/ApiTypes";

export interface ITicketStatsPieGrid {
    hasTeam?: boolean, 
    isLoading: boolean, 
    statistics: Array<any>, 
    title: string,
    cardColor?: AppColor
}

export default function TicketStatsPieGrid({statistics, cardColor, title, hasTeam, isLoading}: ITicketStatsPieGrid) {
    const {
        getName
    } = useUserUtil();

    const getStatsPie = (title: string, dataKey: keyof IUserStatisticsCounts) => (
        <TicketStatisticPie
            cardColor={cardColor}
            title={title}
            loading={isLoading}
            statistics={statistics && statistics.length ? statistics.map(t => ({
                name: hasTeam ? t.team.name : getName(t.user),
                value: hasTeam ? t.statistics.tickets.counts[dataKey] : t.tickets.counts[dataKey],
                fill: hasTeam ? getColorOrShade(t.team.color) : generatePleasantBlueHex()
            })) : []}
        />
    )

    return (
        <Flex className="w-100">
            <Typography color="primary" size={20} >{title}</Typography>
            <div className="ticket-statistics-grid w-100">
                { getStatsPie("Unbeantwortete Tickets", "unansweredTickets") }
                { getStatsPie("Offene Tickets", "openTicketsCurrently") }
                { getStatsPie("Tickets in Wiedervorlage", "ticketsInResubmission")}
                { getStatsPie("Geschlossene Tickets letzte 7 Tage", "closedTicketsPastWeek") }
                { getStatsPie("Tickets in Eskalation", "ticketsInEscalation") }
            </div>
        </Flex>
    )
}