import React from "react";
import { ITicket } from "../../../types/ApiTypes";
import useFilteredTickets from "../../../hooks/useFilteredTickets";
import { useNavigate } from "react-router-dom";
import { useTicketsWithFilter } from "../../../state/swr/tickets/useTicketsWithFilter";
import Button from "../../buttons/Button";

export interface INextTicketButtonProps {
    ticket: ITicket
}

export default function NextTicketButton({ticket}: INextTicketButtonProps) {
    
    const {
        query,
        getFilterForRoute
    } = useFilteredTickets();
    
    const navigate = useNavigate();
    
    const { tickets } = useTicketsWithFilter(getFilterForRoute());
    
    if (!tickets || !tickets.length) return null;

    const getNextTicket = () => {
        const index = tickets.findIndex(t => t.ticket._id === ticket._id);
        if (index === -1) return null;
        if (index === tickets.length - 1) return null;
        return tickets[index + 1];
    }


    const openUrl = (t: ITicket) => navigate(`/tickets/${t._id}?${query.toString()}`);

    const nextTicket = getNextTicket();

    if (!nextTicket) return null;

    return (
        <Button 
            tooltip="Nächstes Ticket in der aktuellen Liste"
            variant="icon" 
            icon="arrow-right"
            onClick={async () => openUrl(nextTicket.ticket)}
        /> 
    )
}