import { Form, Formik } from "formik";
import React from "react";
import { tenantsUpdate, tenantsUpdateMailAccount } from "../../../api/Api";
import { ITenantUpdateMailAccountRequest, ITenantUpdateSettingsRequest } from "../../../api/ApiRequests";
import { AppRoutes } from "../../../config/AppRoutes";
import useApi from "../../../hooks/useApi";
import useTenantSettings from "../../../hooks/useTenantSettings";
import { useTenant } from "../../../state/swr/tenant/useTenant";
import { useTenants } from "../../../state/swr/tenant/useTenants";
import { DocumentClearanceReminderType, ITenant, TenantLogoStyle } from "../../../types/ApiTypes";
import Button from "../../buttons/Button";
import GrantCdpConsent from "../../cdp/GrantCdpConsent";
import Select from "../../comboBox/Select";
import Flex from "../../container/Flex";
import TicketEscalationList from "../../escalation/TicketEscalationList";
import ImageSelect from "../../files/ImageSelect";
import CheckBox from "../../formik/CheckBox";
import ColorPicker from "../../formik/ColorPicker";
import FieldWithLabel from "../../formik/FormikField";
import Hyperlink from "../../links/Hyperlink";
import LoadingSpinner from "../../loader/LoadingSpinner";
import OpeningHoursUpdateForm from "../../openinghours/OpeningHoursUpdateForm";
import WithPermissions from "../../permissions/WithPermissions";
import SignatureUpdateForm from "../../signatures/SignatureUpdateForm";
import TabSwitcher from "../../tabswitcher/TabSwitcher";
import Typography from "../../text/Typography";
import UserSelect from "../../user/UserSelect";
import TenantAbortDeleteButton from "../TenantAbortDeleteButton";
import TenantDeleteButton from "../TenantDeleteButton";

export enum TenantSettingsPage {
    General = "general",
    OpeningHours = "openinghours",
    TicketEscalation = "escalation",
    DocumentReminder = "reminders",
    Mail = "mail",
    Signature = "signature",
    Tickets = "tickets",
    Cdp = "cdp",
    DangerZone = "dangerzone"
}

export interface ITenantUpdateFormValues extends ITenantUpdateSettingsRequest {
    name: string
}

interface ITenantUpdateMailFormValues extends ITenantUpdateMailAccountRequest { }

export default function TenantUpdateForm({tenant, afterSubmit, canDelete = true}: {tenant: ITenant, canDelete?: boolean, afterSubmit?: () => void}) {

    const {
        titles
    } = useTenantSettings(true);

    const [page, setPage] = React.useState<TenantSettingsPage>(TenantSettingsPage.General);

    const { reloadTenants } = useTenants();
    const { tenant: currentTenant, reloadTenant } = useTenant();

    const settings = useTenantSettings(false, tenant, currentTenant ? tenant._id === currentTenant._id : false);
    const callApi = useApi();

    const getContent = () => {
        switch (page) {
            case TenantSettingsPage.Cdp: return <GrantCdpConsent />

            case TenantSettingsPage.Tickets: return (
                <Formik
                    enableReinitialize
                    initialValues={{
                        ...settings,
                        name: tenant.name,
                        tenantId: tenant._id,
                    } as ITenantUpdateFormValues}
                    onSubmit={async (values, actions) => {
                        const result = await callApi(tenantsUpdate(values));
                        
                        if (!result) return;

                        if (afterSubmit) afterSubmit();

                        await reloadTenants();

                        if (!currentTenant) return;

                        if (values.tenantId === currentTenant._id) await reloadTenant();

                    }}
                >
                    {
                        formik => (
                            <Form className="w-100">
                                <Flex fullWidth gap={4}>
                                    <Flex fullWidth gap={2}>
                                        <h6 className="fw-bold m-0">Statistiken</h6>
                                        <FieldWithLabel className="w-100" label="Vorgabe für Minuten bis zur ersten Nachricht" min="15" type="number" placeholder="Vorgabe für Zeit bis zur ersten Nachricht" name="tickets.maxMinutesToFirstResponse" />
                                    </Flex>
                                    <Flex fullWidth gap={2}>
                                        <h6 className="fw-bold m-0">Eskalation</h6>
                                        <span style={{fontSize: "0.8em"}}>Wählen Sie hier den Nutzer, an welchen im Fall der Fälle standardmäßig eskaliert wird.</span>
                                        <UserSelect 
                                            placeholder="Vorgesetzten auswählen..."
                                            displayed="employees"
                                            label="Eskalationsvorgesetzter (Kanzlei)"
                                            onChange={(val) => formik.setFieldValue("tickets.escalationSupervisor", val?._id ?? "")}
                                            value={formik.values.tickets.escalationSupervisor}
                                        />
                                    </Flex>
                                    <Button disabled={!formik.dirty} loading={formik.isSubmitting} type="submit" icon="save" text="Speichern" color="success" />
                                </Flex>
                            </Form>
                        )
                    }
                </Formik>
            )
            case TenantSettingsPage.General: return (
                <Formik
                    enableReinitialize
                    initialValues={{
                        ...settings,
                        name: tenant.name,
                        tenantId: tenant._id,
                    } as ITenantUpdateFormValues}
                    onSubmit={async (values, actions) => {
                        const result = await callApi(tenantsUpdate(values));
                        
                        if (!result) return;

                        if (afterSubmit) afterSubmit();

                        await reloadTenants();

                        if (!currentTenant) return;

                        if (values.tenantId === currentTenant._id) await reloadTenant();

                    }}
                >
                    {
                        (formik) => {

                            return (
                                <Form className="w-100 h-100 d-flex flex-column gap-4 text-start">
                                    <div className="d-flex flex-column w-100 gap-2">
                                        <h6 className="fw-bold m-0">Anzeige</h6>
                                        <FieldWithLabel label="Name" name="name" />
                                        <Flex>
                                            <Typography>Logo-Stil</Typography>
                                            <Select 
                                                value={formik.values.ui?.sidebar?.logoStyle}
                                                values={[
                                                    { data: TenantLogoStyle.LogoAndText, label: "Logo und Text" },
                                                    { data: TenantLogoStyle.LogoOnly, label: "Nur Logo" },
                                                    { data: TenantLogoStyle.TextOnly, label: "Nur Text" }
                                                ]}
                                                onChange={(val) => formik.setFieldValue("ui.sidebar.logoStyle", val)}
                                            />
                                        </Flex>
                                    </div>
                                    <div className="d-flex flex-column w-100 gap-2">
                                        <h6 className="fw-bold">Titel und Bezeichnungen</h6>
                                        <FieldWithLabel name="titles.siteHeader" label="Menütitel" />
                                        <FieldWithLabel name="titles.tenantWord" label="Bezeichnung für Tenant" placeholder="z.B. Kanzlei, Beratungsgesellschaft..."/>
                                        <FieldWithLabel name="titles.documentClearanceWord" label="Bezeichnung für Belegabgabe" placeholder="z.B. Buchhaltungsfreigabe, Belegübermittlung..."/>
                                    </div>
                                    <div className="d-flex flex-column w-100 gap-2">
                                        <h6 className="fw-bold">Links</h6>
                                        <FieldWithLabel name="links.privacyPolicy" label="Datenschutzerklärung" />
                                        <FieldWithLabel name="links.imprint" label="Impressum" />
                                    </div>
                                    <div className="d-flex flex-column w-100 gap-2">
                                        <h6 className="fw-bold">Theme</h6>
                                        <div className="d-flex flex-column gap-1">
                                            <span>Theme-Farbe</span>
                                            <div className="d-flex flex-row gap-5 align-items-center">
                                                <ColorPicker name="theme.primaryColor" />
                                                <Button text="Farbdemo" style={{backgroundColor: formik.values.theme ? formik.values.theme.primaryColor : "#000000"}} />
                                                <h6 className="fw-bold m-0" style={{color: formik.values.theme ? formik.values.theme.primaryColor : "#000000"}}>Farbdemo</h6>
                                                <LoadingSpinner hexColor={formik.values.theme ? formik.values.theme.primaryColor : "#000000"} text="Farbdemo" /> 
                                            </div>
                                        </div>
                                        <div className="d-flex flex-column gap-1">
                                            <strong>Logo</strong>
                                            <ImageSelect image={formik.values.theme ? formik.values.theme.logo : undefined} saveImage={(img) => formik.setFieldValue("theme.logo", img)} />
                                        </div>
                                    </div>
                                    <div className="d-flex flex-column w-100 gap-2">
                                        <h6 className="fw-bold">Tickets</h6>
                                        <CheckBox name="options.sendTicketMailsToClients" label="Ticket-Mails an Mandanten senden" />
                                    </div>
                                    <Button disabled={!formik.dirty} loading={formik.isSubmitting} type="submit" icon="save" text="Speichern" color="success" />
                                </Form>
                            )
                        }
                    }
                </Formik>
            );
            case TenantSettingsPage.DocumentReminder: return (
                <Formik
                    enableReinitialize
                    initialValues={{
                        ...settings,
                        name: tenant.name,
                        tenantId: tenant._id,
                    } as ITenantUpdateFormValues}
                    onSubmit={async (values, actions) => {
                        const result = await callApi(tenantsUpdate(values));
                        
                        if (!result) return;

                        if (afterSubmit) afterSubmit();

                        await reloadTenants();

                        if (!currentTenant) return;

                        if (values.tenantId === currentTenant._id) await reloadTenant();

                    }}
                    >
                    {
                        (formik) => {
                            return (
                                <Form className="w-100 h-100 d-flex flex-column gap-4 text-start">
                                    <div className="d-flex flex-column w-100 gap-2">
                                        <h6 className="fw-bold">{titles?.documentClearanceWord || "Belegabgabe"}</h6>
                                        <FieldWithLabel label="Erinnerung senden am" noMargin type="number" className="w-100" name="options.documentClearance.defaultReminderDayOfMonth" min="1" max="28" step="1" />
                                        <FieldWithLabel label="Anzahl der Erinnerungsmails" noMargin type="number" className="w-100" name="options.documentClearance.defaultReminderCount" min="0" max="5" step="1" />
                                        <FieldWithLabel label="Tage zwischen den Erinnerungen" noMargin type="number" className="w-100" name="options.documentClearance.defaultReminderInterval" min="1" max="14" step="1" />
                                        <Select
                                            label="Erinnerungstyp"
                                            values={[
                                                { data: DocumentClearanceReminderType.LinkAndText, label: "Link und Text" },
                                                { data: DocumentClearanceReminderType.TextOnly, label: "Nur Text" },
                                            ]}
                                            value={formik.values.options.documentClearance.reminderType}
                                            onChange={o => formik.setFieldValue("options.documentClearance.reminderType", o)}
                                        />
                                    </div>
                                    <Button disabled={!formik.dirty} loading={formik.isSubmitting} type="submit" icon="save" text="Speichern" color="success" />
                                </Form>
                            )
                        }
                    }
                </Formik>
            );
            case TenantSettingsPage.Mail: return (
                <Formik
                    enableReinitialize
                    initialValues={{
                        id: tenant._id,
                        usersReceiveUnsafeAttachmentsPerMailDefault: tenant?.settings?.options?.usersReceiveUnsafeAttachmentsPerMail || false,
                        sendMailsPreferrablyWithPersonalAccounts: tenant.settings.options.sendMailsPreferrablyWithPersonalAccounts || false,
                        dontSendTicketErrorMailsWithPersonalAccount: tenant.settings.options.dontSendTicketErrorMailsWithPersonalAccount || false,
                        dontSendTicketErrorMails: tenant.settings.options.dontSendTicketErrorMails || false,
                        sendMessageHistoryWithEveryTicketMail: tenant.settings.tickets.sendMessageHistoryWithEveryTicketMail || false,
                        sendTicketOpenedMail: tenant.settings.mails.sendTicketOpenedMail || false
                    } as ITenantUpdateMailFormValues}
                    onSubmit={async (values) => {
                        const res = await callApi(tenantsUpdateMailAccount(values), true);

                        if (!res || !res.success) return;
                        if (afterSubmit) afterSubmit();
                    }}
                >
                    {
                        formik => (
                            <Form className="w-100">
                                <Flex className="w-100" gap={3}>
                                    <h6 className="fw-bold">Mailkonto</h6>
                                    <p>Das Mailkonto können Sie unter <Hyperlink to={AppRoutes.MailManagement.path}>Mailkonto</Hyperlink> verwalten.</p>
                                    <strong>Mandantenkommunikation</strong>
                                    <Flex gap={1}>
                                        <CheckBox label="Nutzer erhalten Anhänge von Ticketnachrichten per Mail" name="usersReceiveUnsafeAttachmentsPerMailDefault" />
                                        <span style={{fontSize: "0.8em"}}>Mit dieser Option wird der von jedem Nutzer eingestellte Wert überschrieben und der Nutzer muss seine Präferenz gegebenenfalls neu einstellen.</span>
                                    </Flex>
                                    <Flex gap={1}>
                                        <CheckBox label="Persönliche Mailkonten bevorzugt für das Senden von Ticketmails verwenden" name="sendMailsPreferrablyWithPersonalAccounts" />
                                        <span style={{fontSize: "0.8em"}}>Diese Option ankreuzen, um bei Ticketaktivitäten die persönliche Mail des Bearbeiters zum Versenden der Mail zu verwenden. Dies kann den Mandantenbezug erhöhen.</span>
                                    </Flex>
                                    <Flex gap={1}>
                                        <CheckBox label="Keine Ticketfehlermails versenden" name="dontSendTicketErrorMails" />
                                        <span style={{fontSize: "0.8em"}}>Diese Option ankreuzen, um keine Fehlermails bei nicht eröffneten Tickets zu versenden.</span>
                                    </Flex>
                                    {
                                        !formik.values.dontSendTicketErrorMails && (
                                            <Flex gap={1}>
                                                <CheckBox label="Keine Ticketfehlermails über persönliche Mailkonten versenden" name="dontSendTicketErrorMailsWithPersonalAccount" />
                                                <span style={{fontSize: "0.8em"}}>Diese Option ankreuzen, um keine Fehlermails bei nicht eröffneten Tickets über persönliche Mailkonten zu versenden.</span>
                                            </Flex>
                                        )
                                    }
                                    <Flex fullWidth gap={2}>
                                        <CheckBox label="Nachrichten-Historie mit jeder Mail mitsenden" name="sendMessageHistoryWithEveryTicketMail" />
                                        <span style={{fontSize: "0.8em"}}>Mit dieser Option wird die Nachrichten-Historie für ein Ticket mit jeder Benachrichtigung für dieses Ticket mitgesandt.</span>
                                    </Flex>
                                    <Flex gap={1}>
                                        <CheckBox label="Eröffnungs-Mail für ein Ticket senden" name="sendTicketOpenedMail" />
                                        <span style={{fontSize: "0.8em"}}>Mit dieser Option wird nach der Eröffnung eines Tickets eine Mail versandt.</span>
                                    </Flex>
                                    <Button disabled={!formik.dirty} loading={formik.isSubmitting} type="submit" icon="save" text="Speichern" color="success" />
                                </Flex>
                            </Form>
                        )
                    }

                </Formik>
            );
            case TenantSettingsPage.TicketEscalation: return <TicketEscalationList />
            case TenantSettingsPage.OpeningHours: return <OpeningHoursUpdateForm />;
            case TenantSettingsPage.DangerZone: return (
                <Flex justify="start">
                    {
                        tenant && canDelete && (
                            <TenantDeleteButton tenant={tenant} afterDelete={() => {
                                if (afterSubmit) afterSubmit();
                                reloadTenants();
                            }} />
                        )
                    }
                    <TenantAbortDeleteButton tenant={tenant} afterSubmit={() => {
                        if (afterSubmit) afterSubmit();
                        reloadTenants();
                    }} />
                </Flex>
            );

            case TenantSettingsPage.Signature: return <SignatureUpdateForm />
        }
    }

    return (
        <WithPermissions permissions={["tenant.own.update"]}>
            <Flex gap={3} className="w-100">
                <TabSwitcher 
                    size="small"
                    tabQueryParamKey="settings" 
                    tabs={[
                        {
                            data: TenantSettingsPage.General,
                            label: "Allgemein"
                        },
                        {
                            data: TenantSettingsPage.Tickets,
                            label: "Tickets",
                        },
                        {
                            data: TenantSettingsPage.OpeningHours,
                            label: "Öffnungszeiten"
                        },
                        {
                            data: TenantSettingsPage.DocumentReminder,
                            label: titles?.documentClearanceWord || "Belegabgabe"
                        },
                        {
                            data: TenantSettingsPage.TicketEscalation,
                            label: "Eskalations-Workflow"
                        },
                        {
                            data: TenantSettingsPage.Mail,
                            label: "Mails"
                        },
                        {
                            data: TenantSettingsPage.Signature,
                            label: "Signatur",
                        },
                        {
                            data: TenantSettingsPage.Cdp,
                            label: "Datenschutz und Privatsphäre"
                        },
                        {
                            data: TenantSettingsPage.DangerZone,
                            label: "Gefahrenzone",
                            hidden: !canDelete,
                            backColor: "red",
                            foreColor: "red"
                        }
                    ]}
                    saveActiveTab={t => setPage(t as TenantSettingsPage)}
                />
                {
                    getContent()
                }
            </Flex>
        </WithPermissions>
    )
}