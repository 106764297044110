import useSWR from "swr";
import { IClient, IUser, IUserClients } from "../../../types/ApiTypes";
import { ApiRoutes, RouteParam } from "../../../api/ApiRoutes";
import { ICollection } from "../../../types/collection.types";

export const useUsers = () => {
    const { data, isLoading, mutate } = useSWR<ICollection<IUser>>(ApiRoutes.User.All);
    
    return {
        users: data?.items ?? [],
        usersById: data?.byId ?? {},
        loadingUsers: isLoading,
        reloadUsers: mutate
    }
}