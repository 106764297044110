import "./style/root.css";
import './index.css';
import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import { Provider } from "react-redux";
import ErrorBoundary from "./app/ErrorBoundary";
import ScrollToTopOnRouteChange from "./components/util/UpdateStatesOnRouteChange";
import App from "./app/App";
import store from "./state/store";
import ModalBanner from "./components/modal/ModalBanner";
import TenantThemeProvider from "./components/tenant/TenantThemeProvider";
import SWRProvider from "./app/SWRProvider";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import QueryProvider from "./app/QueryProvider";
import ModalNotifications from "./components/modal/ModalNotifications";

const element = document.getElementById("root");

if (!!element) {
  const root = ReactDOM.createRoot(element);

  root.render(
    <React.StrictMode>
        <Provider store={store}>
            <QueryProvider>
                <SWRProvider>
                    <BrowserRouter>
                        <ErrorBoundary>
                            <ScrollToTopOnRouteChange>
                                <TenantThemeProvider />
                                <App />
                                <ModalBanner />
                                <ModalNotifications />
                            </ScrollToTopOnRouteChange>
                        </ErrorBoundary>
                    </BrowserRouter>
                </SWRProvider>
            </QueryProvider>
        </Provider>
    </React.StrictMode>
  );
} 

