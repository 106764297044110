import React from "react"
import { IMailLog } from "../../types/ApiTypes";
import Card from "../card/Card";
import Flex from "../container/Flex";
import { formatDateGmailStyle, formatDateTiggiStyle, formatMailAddress } from "../../util/formatter";
import MailLogTypeBanner from "./MailLogTypeBanner";
import MailLogSourceBanner from "./MailLogSourceBanner";
import MailRejectReasonBanner from "./MailRejectReasonBanner";
import TicketChatHtmlMessageContent from "../ticketing/ticketChat/TicketChatHtmlMessageContent";

export default function MailLogCard({item}: {item: IMailLog}) {
    return (
        <Card background="var(--backgroundLighter)" >
            <Flex className="w-100">
                <Flex className="w-100" row justify="between">
                    
                </Flex>
                <Flex gap={0}>
                    <Flex row>
                    <em style={{minWidth: "70px"}}>Datum</em>
                        {item.createdAt && <span>{formatDateTiggiStyle(item.createdAt)}</span>} 
                    </Flex>
                    <Flex row>
                        <em style={{minWidth: "70px"}}>Von</em>
                        <span>{formatMailAddress(item.fromName, item.fromMail)}</span>
                    </Flex>
                    <Flex row>
                        <em style={{minWidth: "70px"}}>An</em>
                        <span>{item.toMail}</span>
                    </Flex>
                </Flex>
                <Flex row>
                    <MailLogTypeBanner type={item.type} />
                    <MailLogSourceBanner source={item.source} />
                    <MailRejectReasonBanner reason={item.rejectReason} />
                </Flex>
            </Flex>
        </Card>
    )
}