import {configureStore} from "@reduxjs/toolkit";
import { Cookies, getCookie, setCookie } from "../util/cookies";
import ModalSlice from "./slices/modalSlice";
import SidebarSlice from "./slices/sidebarSlice";
import ClientImportSlice from "./slices/clientImportSlice";
import VersionSlice, { getVersion } from "./slices/versionSlice";
import NotificationSlice from "./slices/notificationSlice";

const store = configureStore({
    reducer: {
        modal: ModalSlice,
        sidebar: SidebarSlice,
        version: VersionSlice,
        clientImport: ClientImportSlice,
        notifications: NotificationSlice
    }
})

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

export default store;