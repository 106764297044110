import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import useTicketPermission from "../../../hooks/useTicketPermissions";
import { useTicket } from "../../../state/swr/tickets/useTicket";
import { useTicketAssignee } from "../../../state/swr/tickets/useTicketAssignee";
import { useTicketClientContact } from "../../../state/swr/tickets/useTicketClientContact";
import { useTicketMessages } from "../../../state/swr/tickets/useTicketMessages";
import { useCurrentTenantRole } from "../../../state/swr/user/useCurrentTenantRole";
import { ITicket, TicketState } from "../../../types/ApiTypes";
import IElementProps from "../../../types/ElementProps";
import ClientTypeBanner from "../../clients/ClientTypeBanner";
import Flex from "../../container/Flex";
import LoadingSpinner from "../../loader/LoadingSpinner";
import WithPermissions from "../../permissions/WithPermissions";
import Pill from "../../pills/Pill";
import TeamBanner from "../../teams/TeamBanner";
import UserRoleBanner from "../../user/RoleBanner";
import TicketStateBanner from "../banner/TicketStateBanner";
import UpdateTicketStatusButton from "../status/UpdateTicketStatusButton";
import "./TicketChat.css";
import TicketChatForm from "./TicketChatForm";
import TicketChatMessage from "./TicketChatMessage";
import TicketMentionsBanner from "./TicketMentionsBanner";
import TicketNotes from "./TicketNotes";

interface ITicketChatProps extends IElementProps {
    ticket: ITicket
}

interface ITicketColorMap {
    ticketId: string,
    colorMap: {[userId: string]: string}
}

export default function TicketChat({ticket}: ITicketChatProps) {
    
    const permissions = useTicketPermission("read", ticket);


    const { ticketAssignee, loadingTicketAssignee } = useTicketAssignee(ticket);
    const { loadingTicketClientContact, ticketClientContact } = useTicketClientContact(ticket);

    const { loadingTicket } = useTicket(ticket._id);
    const { messages, loadingMessages } = useTicketMessages(ticket._id);

    const chatScrollRef = React.useRef<HTMLDivElement>(null);
    const currentColorMap = React.useRef<ITicketColorMap>({colorMap: {}, ticketId: ""});

    const navigate = useNavigate();

    const { role } = useCurrentTenantRole();

    useEffect(() => {
        setTimeout(() => {
            if (!chatScrollRef || !chatScrollRef.current) return;
            chatScrollRef.current.scrollIntoView();
        }, 50);
    }, [ticket, messages]);

    if (!ticket) return null;

    const isClient = role && role.isClient;

    return (
        <WithPermissions permissions={permissions}>
            <div className="h-100 w-100 d-flex ticket-chat gap-3 flex-column">
                <Flex row justify="between" wrap className="w-100">
                    <Flex wrap row>
                        <TicketStateBanner ticket={ticket} align="left" />
                        {
                            loadingTicket && <LoadingSpinner />
                        }
                        {
                            !isClient && ticketAssignee && ticketAssignee.user && <UserRoleBanner user={ticketAssignee.user} displayNameAsValue showOnEmptyText="Kein Bearbeiter"/>
                        }
                        {
                            !isClient && ticketClientContact && ticketClientContact.client && <ClientTypeBanner client={ticketClientContact.client} showClientName textOnEmpty="Kein Mandant" />
                        }
                        {
                            !isClient && <TeamBanner team={ticket.team} textOnEmpty="Kein Team"/>
                        }
                        {
                            ticketAssignee && !!ticketAssignee.deputy && <Pill text="Bearbeitung in Vertretung" color="#FF4500" />
                        }
                        <TicketMentionsBanner ticket={ticket} />
                    </Flex>
                    <Flex row gap={0} reverse className="ms-auto">
                        <UpdateTicketStatusButton tickets={[ticket]} isSingleTicket state={TicketState.Open} variant="text" />
                        <UpdateTicketStatusButton tickets={[ticket]} isSingleTicket state={TicketState.Resubmission} variant="text" />
                        <UpdateTicketStatusButton tickets={[ticket]} isSingleTicket state={TicketState.Solved} variant="text" />
                        <UpdateTicketStatusButton tickets={[ticket]} isSingleTicket isDelete variant="text" />
                    </Flex>
                </Flex>
                <Flex className="ticket-chat-container h-100">
                    <div className="d-flex flex-column gap-2 w-100" >
                        {
                            loadingMessages
                            ? <LoadingSpinner />
                            : (
                                messages && !!messages.length
                                ? messages.map((m: any) => <TicketChatMessage key={m._id} colorMap={currentColorMap.current.colorMap} ticket={ticket} message={m} />)
                                : <strong>Noch keine Nachrichten.</strong>
                            )

                        }
                        {
                            ticket && ticket.typingUser && <div className="ticket-chat-form-user-typing-notice"><strong>{ticket.typingUser.mailAddress}</strong> schreibt...</div>
                        }
                        <div ref={chatScrollRef} />
                    </div>
                </Flex>
                <TicketChatForm ticket={ticket} />
                <TicketNotes ticket={ticket} />
            </div>
        </WithPermissions>
    )
}
