import React from "react";
import { IUser } from "../../types/ApiTypes";
import { useUsers } from "../../state/swr/user/useUsers";
import LoadingSpinner from "../loader/LoadingSpinner";
import SearchableComboBox from "../comboBox/SearchableComboBox";
import UserRoleBanner from "./RoleBanner";
import useUserUtil from "../../hooks/useUserUtil";
import useFilteredUsers, { DisplayedUsers, IWithFilteredUsers } from "../../hooks/useFilteredUsers";

export interface IUserSelectProps extends IWithFilteredUsers {	
    onChange: (user: IUser | undefined) => void,
    value?: string,
    className?: string,
    bold?: boolean,
    label: string,
    placeholder?: string
}

export default function UserSelect({onChange, placeholder, label, bold, className, value, displayed = "all", showWithoutRole}: IUserSelectProps) {
   
    const {
        loading,
        users
    } = useFilteredUsers({ displayed, showWithoutRole });

    const {
        getName
    } = useUserUtil();

    if (loading) return <LoadingSpinner />

    return (
        <SearchableComboBox 
            bold={bold}
            className={className || "w-100"}
            values={users}
            itemToId={(u) => u._id}
            renderItem={(u) => <UserRoleBanner className="w-100" displayNameAsValue user={u} showMail />}
            itemToString={(u) => u.mailAddress}
            filterFunction={(u, f) => {
                if (!u) return false;

                const mailIncludesValue = u.mailAddress.toUpperCase().includes(f);

                if (mailIncludesValue) return true;

                const name = getName(u);

                if (!name) return false;
                
                return name.toUpperCase().includes(f);
            }}
            renderValue={(u) => <UserRoleBanner displayNameAsValue user={u} showMail />}
            clearValue={() => onChange(undefined)}
            onItemClick={onChange}
            label={label}
            placeholder={placeholder || "Nutzer auswählen..."}
            value={value}

        />
    )

}