import React from "react";
import { ITeamBase } from "../../types/ApiTypes";
import Banner from "../pills/Banner";
import Pill from "../pills/Pill";
import { useTeams } from "../../state/swr/teams/useTeams";

export interface ITeamBanner {
    team: ITeamBase | string,
    onClick?: () => void,
    isActive?: boolean,
    textOnEmpty?: string
}

export default function TeamBanner({team, textOnEmpty, isActive = true, onClick}: ITeamBanner) {

    const {
        teamsById,
        loadingTeams
    } = useTeams();

    if (loadingTeams) return <Pill loading loadingText="Lädt..." />

    const usedTeam = typeof team === "string" ? teamsById[team] : team;

    if (!usedTeam) {
        if (textOnEmpty) return <Pill text={textOnEmpty} icon="people" color="bright" />
        return null;
    }
    
    const inactiveColor = "#A0A0A0";
    const color = (isActive && usedTeam.color) || inactiveColor;

    return (
        <Pill text={usedTeam.name} icon="people" color={color} onClick={onClick} />
    )
}